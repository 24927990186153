import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNode } from 'react';
import {
  BlockingAffectedResError,
  SeatingAbortError,
} from '../types/reservation';
import { isProduction } from '../utils';

export const basicErrHandler = (error: any) => {
  console.error(error);
  /**
   * forwarded from host v2 backend
   * not to be confused with axios response status:
   * ```
   * const v2StatusCode = error?.response?.data?.statusCode;
   * const statusCode = error?.response?.status;
   * ```
   */
  const v2StatusCode = error?.response?.data?.statusCode;
  if (v2StatusCode === 401) {
    // already handled in use-v2-api
    return;
  }
  // handled individually if it is login error
  const url: string = error?.response?.config?.url || '';
  if (
    (url.includes('/api/auth/pin-login-remember-me') ||
      url.includes('/api/auth/email-login-remember-me')) &&
    (v2StatusCode === 401 || v2StatusCode === 422)
  ) {
    return;
  }
  if (error instanceof BlockingAffectedResError) {
    // handled individually
    return;
  }
  if (error instanceof SeatingAbortError) {
    // handled individually
    return;
  }
  let errorMsg = 'An unexpected error has occurred!';
  const possibleMsgs = [
    error?.response?.data?.message,
    error?.response?.data?.rawMessage,
    error?.response?.data?.error,
    error?.message,
    error?.response?.statusText,
  ];
  errorMsg = [
    errorMsg,
    possibleMsgs.find((it) => typeof it === 'string' || typeof it === 'number'),
  ]
    .filter(Boolean)
    .join('\n');
  alert(errorMsg);
};

export const qClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: basicErrHandler,
    },
    queries: {
      onError: basicErrHandler,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 1,
    },
  },
});

export const QClientProvider = ({ children }: { children: ReactNode }) => (
  <QueryClientProvider client={qClient}>{children}</QueryClientProvider>
);
