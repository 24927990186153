import { z } from 'zod';

export const ZCustomerWithoutRef = z.object({
  id: z.string(),
  oddlePassId: z.string().nullable().optional(),
  mcaId: z.string().nullable().optional(),
  merchantId: z.string().nullable().optional(),
  brandId: z.string().nullable().optional(),
  favouriteItemIds: z.string().nullable().optional(),
  username: z.string().nullable().optional(),
  password: z.string().nullable().optional(),
  salutation: z.string().nullable().optional(),
  firstName: z.string().nullable().optional(),
  lastName: z.string().nullable().optional(),
  email: z.string().nullable().optional(),
  phone: z.string().nullable().optional(),
  additionalPhone: z.string().nullable().optional(),
  organisationName: z.string().nullable().optional(),
  address: z.string().nullable().optional(),
  gender: z.number().nullable().optional(),
  birthday: z.string().nullable().optional(),
  barcode: z.string().nullable().optional(),
  language: z.string().nullable().optional(),
  postalCode: z.string().nullable().optional(),
  countryCode: z.string().nullable().optional(),
  state: z.string().nullable().optional(),
  city: z.string().nullable().optional(),
  notesDiner: z.string().nullable().optional(),
  canSendSmsMarketing: z
    .union([z.literal(0), z.literal(1)])
    .nullish()
    .catch(null),
  canSendEmailMarketing: z.boolean().nullable().optional(),
  accountActivated: z.boolean(),
  createdTimestamp: z.string().nullable().optional(),
  modifiedTimestamp: z.string().nullable().optional(),
  photoUrl: z.string().optional().nullable(),
});
