import { Global, css } from '@emotion/react';
import { GREY } from './minimals.cc/theme/palette';

export default function GlobalStyles() {
  return (
    <Global
      styles={css`
        html,
        body {
          font-family: Public Sans, system;
          background-color: ${GREY[200]};
        }

        @media all and (display-mode: standalone) {
          html,
          body {
            overscroll-behavior-y: none;
          }
        }

        a {
          text-decoration: none;
        }

        .div-center {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .MuiDialog-root > .MuiBackdrop-root {
          background: rgba(22, 28, 36, 0.72);
        }

        /* increase specificity */
        div.MuiCardHeader-root {
          padding: 16px 16px 0px;
        }
        /* increase specificity */
        div.MuiCardContent-root {
          padding: 16px;
        }
        h2.MuiDialogTitle-root {
          padding: 16px 12px 16px 16px;
        }

        // notification tablist
        div[role='tablist'] > button[role='tab'] {
          font-family: 'Public Sans', system-ui, -apple-system,
            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
            'Open Sans', 'Helvetica Neue', sans-serif;
        }
      `}
    />
  );
}
