import { m as motion, MotionProps, Variants } from 'framer-motion';

import {
  MAIN_COLOUR,
  progressBarDimensions,
  SVG_CIRCLE_HIDE_DURATION,
  thumbMargins,
  TRACK_SHRINK_DURATION,
} from './constants';

export const svgCircleVariants: Variants = {
  init: {
    strokeDashoffset: 0.75,
    transition: { ease: 'linear', duration: 1 },
  },
  show: {
    opacity: 1,
  },
  rotate: {
    rotate: 360,
    transition: {
      ease: 'linear',
      duration: 1,
      repeat: Number.POSITIVE_INFINITY,
    },
  },
  // init + show + rotate without strokeDashoffset
  start: {
    opacity: 1,
    transition: {
      delay: TRACK_SHRINK_DURATION,
      ease: 'linear',
      duration: 1,
      repeat: Number.POSITIVE_INFINITY,
    },
    rotate: 360,
  },
  hide: {
    opacity: 0,
    rotate: 0,
    transition: { ease: 'linear', duration: SVG_CIRCLE_HIDE_DURATION },
  },
};

const SvgCircle = (props: MotionProps) => (
  <motion.svg
    height={progressBarDimensions}
    width={progressBarDimensions}
    {...props}
  >
    <circle
      cx={progressBarDimensions / 2}
      cy={progressBarDimensions / 2}
      r={progressBarDimensions / 2 - thumbMargins / 2}
      stroke={MAIN_COLOUR}
      strokeWidth={thumbMargins}
      pathLength={1}
      strokeDasharray={1}
      strokeDashoffset={0.75}
      fill="none"
    />
  </motion.svg>
);

export default SvgCircle;
