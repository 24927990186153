import { ErrorBoundary } from 'react-error-boundary';
import { serializeError } from 'serialize-error';

const FallbackComponent = () => <div>Something went wrong!</div>;

const logError = async (error: unknown) => {
  await fetch('/api/error-report', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(serializeError(error)),
  });
};

const ErrorBoundaryWithLogs = ({ children }: { children: React.ReactNode }) => {
  return (
    <ErrorBoundary FallbackComponent={FallbackComponent} onError={logError}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWithLogs;
