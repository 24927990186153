import { SessionProvider } from '@/feat/auth/context';
import ProgressBar from '@/minimals.cc/components/ProgressBar';
import MotionLazyContainer from '@/minimals.cc/components/animate/MotionLazyContainer';
import { CollapseDrawerProvider } from '@/minimals.cc/contexts/CollapseDrawerContext';
import MinimalsThemeProvider from '@/minimals.cc/theme';
import '@total-typescript/ts-reset';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { ReactNode, useMemo, useRef, useState } from 'react';
import 'simplebar-react/dist/simplebar.min.css';
// import ThemeSettings from '@/minimals.cc/components/settings';
import ErrorBoundaryWithLogs from '@/common/components/error-boundary';
import { V3SwitchGuard } from '@/common/guards/v3-switch';
import useCallbackRef from '@/common/hooks/use-callback-ref';
import useLocaleCfg, { AutoSwitchLocale } from '@/common/hooks/use-locale-cfg';
import usePrefetchRoutes from '@/common/hooks/use-prefetch-routes';
import { useDraggingListener } from '@/common/hooks/use-prevent-nav-animation';
import { PwaDetect } from '@/common/hooks/use-pwa';
import DateAdapter from '@/common/lib/date-adapter';
import { QClientProvider } from '@/common/lib/react-query';
import {
  NextPageWithLayout,
  isProduction,
  useIsoLayoutEffect,
} from '@/common/utils';
import usePrintDebugInfo from '@/feat/debug/use-print-debug-info';
import GlobalStyles from '@/global-styles';
import breakpoints from '@/minimals.cc/theme/breakpoints';
import { css } from '@emotion/react';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import UAParser from 'ua-parser-js';
import nextI18NextConfig from '../next-i18next.config.js';
import { MagicBellContextProvider } from '@/feat/settings/notifications/magicbell/magicbell-context';

const MqttHandler = dynamic(() => import('@/feat/reservation/mqtt'), {
  ssr: false,
});

const GlobalDeferredComponents = dynamic(
  () => import('@/global-deferred-components'),
  { ssr: false }
);

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const MinimalsProviders = ({ children }: { children: ReactNode }) => (
  <CollapseDrawerProvider>
    <MotionLazyContainer>
      <MinimalsThemeProvider>
        {/* <ThemeSettings> */}
        <ProgressBar />
        {children}
        {/* </ThemeSettings> */}
      </MinimalsThemeProvider>
    </MotionLazyContainer>
  </CollapseDrawerProvider>
);

const LocalizationProvider = ({ children }: { children: ReactNode }) => {
  const locale = useLocaleCfg();

  return (
    <MuiLocalizationProvider
      adapterLocale={locale.dateLocale}
      dateAdapter={DateAdapter}
    >
      {children}
    </MuiLocalizationProvider>
  );
};

const OG_BASE_URL = isProduction()
  ? 'https://host.oddle.me'
  : 'https://staging-reserve-host-v3.vercel.app';
const OG_APP_NAME = 'Oddle Host';
const OG_APP_DESC = 'Oddle Host App'; // TODO

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  const isIosMdUpOrIsRequestDesktopSite = useMemo(() => {
    const device = new UAParser().getDevice();
    return device.vendor === 'Apple' && device.type !== 'mobile';
  }, []);

  const viewPortContent = useMemo(() => {
    const data = isIosMdUpOrIsRequestDesktopSite
      ? { 'initial-scale': 1, width: breakpoints.values.md }
      : { 'initial-scale': 1, width: 'device-width' };

    return Object.entries(data)
      .map((entry) => entry.join('='))
      .join(', ');
  }, [isIosMdUpOrIsRequestDesktopSite]);

  usePrefetchRoutes();
  usePrintDebugInfo();
  useDraggingListener();

  return (
    <>
      <Head>
        <meta name="viewport" content={viewPortContent} />
        <meta name="application-name" content={OG_APP_NAME} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="apple-mobile-web-app-title" content={OG_APP_NAME} />
        <meta name="description" content={OG_APP_NAME} />
        <meta name="mobile-web-app-capable" content="yes" />

        <link rel="apple-touch-icon" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />

        <meta name="twitter:card" content={OG_APP_NAME} />
        <meta name="twitter:url" content={OG_BASE_URL} />
        <meta name="twitter:title" content={OG_APP_NAME} />
        <meta name="twitter:description" content={OG_APP_DESC} />
        <meta
          name="twitter:image"
          content={`${OG_BASE_URL}/favicon/android-chrome-192x192.png`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={OG_APP_NAME} />
        <meta property="og:description" content={OG_APP_DESC} />
        <meta property="og:site_name" content={OG_APP_NAME} />
        <meta property="og:url" content={OG_BASE_URL} />
        <meta
          property="og:image"
          content={`${OG_BASE_URL}/favicon/apple-touch-icon.png`}
        />

        {/* iOS splash screens */}
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href="/splash_screens/iPhone_15_Pro_Max__iPhone_15_Plus__iPhone_14_Pro_Max_landscape.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href="/splash_screens/iPhone_15_Pro__iPhone_15__iPhone_14_Pro_landscape.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href="/splash_screens/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_landscape.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href="/splash_screens/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_landscape.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href="/splash_screens/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_landscape.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href="/splash_screens/iPhone_11_Pro_Max__iPhone_XS_Max_landscape.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/splash_screens/iPhone_11__iPhone_XR_landscape.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href="/splash_screens/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_landscape.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/splash_screens/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_landscape.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/splash_screens/4__iPhone_SE__iPod_touch_5th_generation_and_later_landscape.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/splash_screens/12.9__iPad_Pro_landscape.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/splash_screens/11__iPad_Pro__10.5__iPad_Pro_landscape.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/splash_screens/10.9__iPad_Air_landscape.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/splash_screens/10.5__iPad_Air_landscape.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/splash_screens/10.2__iPad_landscape.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/splash_screens/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_landscape.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 744px) and (device-height: 1133px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/splash_screens/8.3__iPad_Mini_landscape.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/splash_screens/iPhone_15_Pro_Max__iPhone_15_Plus__iPhone_14_Pro_Max_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/splash_screens/iPhone_15_Pro__iPhone_15__iPhone_14_Pro_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/splash_screens/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/splash_screens/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/splash_screens/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/splash_screens/iPhone_11_Pro_Max__iPhone_XS_Max_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/splash_screens/iPhone_11__iPhone_XR_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/splash_screens/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/splash_screens/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/splash_screens/4__iPhone_SE__iPod_touch_5th_generation_and_later_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/splash_screens/12.9__iPad_Pro_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/splash_screens/11__iPad_Pro__10.5__iPad_Pro_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/splash_screens/10.9__iPad_Air_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/splash_screens/10.5__iPad_Air_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/splash_screens/10.2__iPad_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/splash_screens/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_portrait.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 744px) and (device-height: 1133px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/splash_screens/8.3__iPad_Mini_portrait.png"
        />
      </Head>
      <PwaDetect />
      <QClientProvider>
        <SessionProvider pagePropsSessionData={pageProps.sessionData}>
          <MagicBellContextProvider>
            <MinimalsProviders>
              <LocalizationProvider>
                <ErrorBoundaryWithLogs>
                  <MqttHandler />
                  <GlobalStyles />
                  <GlobalDeferredComponents />
                  <V3SwitchGuard>
                    <AutoSwitchLocale />
                    {getLayout(<Component {...pageProps} />)}
                    {/* <DebugComponent /> */}
                  </V3SwitchGuard>
                </ErrorBoundaryWithLogs>
              </LocalizationProvider>
            </MinimalsProviders>
          </MagicBellContextProvider>
        </SessionProvider>
      </QClientProvider>
    </>
  );
}

export default appWithTranslation(MyApp, nextI18NextConfig);

function DebugComponent() {
  const [logs, setLogs] = useState<string[]>([]);
  const counterRef = useRef(0);

  const addLog = useCallbackRef((txt: string) =>
    setLogs((logs) => [`${++counterRef.current} ${txt}`, ...logs].slice(0, 100))
  );

  useIsoLayoutEffect(() => {
    window.ontouchstart = () => addLog('touchstart');
    window.ontouchmove = () => addLog('touchmove');
    window.ontouchend = () => addLog('touchend');
    window.onpopstate = () => addLog('popstate');
  }, []);

  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(255, 0, 0, 0.5);
        width: 200px;
        height: 200px;
        pointer-events: none;
        z-index: 1000000000;
        overflow: auto;
        color: black;
        white-space: pre-wrap;
      `}
    >
      {logs.join('\n')}
    </div>
  );
}
