import { styled, Theme } from '@mui/material/styles';
import ProgressBarAnchor, {
  progressBarAnchorVariants,
} from './ProgressBarAnchor';
import SvgCircle, { svgCircleVariants } from './SvgCircle';
import SwitchThumb, { switchThumbVariants } from './SwitchThumb';
import SwitchThumbInner, {
  switchThumbInnerTransition,
  switchThumbInnerVariants,
} from './SwitchThumbInner';
import Track, { trackTransition, trackVariants } from './Track';
import { SxProps } from '@mui/system';
import React from 'react';

const Container = styled('div')(({ theme }) => ({
  minHeight: '200px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

interface LogoLoadingProps {
  sx?: SxProps<Theme>;
}

const LogoLoading: React.FC<LogoLoadingProps> = ({ sx }) => (
  <Container sx={sx}>
    <Track
      initial="shrink"
      animate="expandAndChangeColor"
      exit="expandAndChangeColor"
      variants={trackVariants}
      transition={trackTransition}
    >
      <ProgressBarAnchor
        initial="hide"
        animate="show"
        variants={progressBarAnchorVariants}
      >
        <SvgCircle animate="hide" exit="hide" variants={svgCircleVariants} />
      </ProgressBarAnchor>
      <SwitchThumb
        variants={switchThumbVariants}
        initial="left"
        animate="right"
      >
        <SwitchThumbInner
          animate="changeColorForward"
          exit="scaleUpAndChangeColor"
          variants={switchThumbInnerVariants}
          transition={switchThumbInnerTransition}
        />
      </SwitchThumb>
    </Track>
  </Container>
);

export default LogoLoading;
