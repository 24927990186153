/**
 * https://oddle-pm.atlassian.net/wiki/spaces/OTR/pages/2929164289/Reservation+Status+Flow+Editable+fields+in+v3
 */

import { ValueOf } from '../utils';
import { ReservationItem } from './reservation';

export const WALKIN_STATUS = [
  'R::SEATED',
  'R::BILL',
  'R::COMPLETED',
  'R::OVERSTAYED',
] as const;
export type WalkinStatus = typeof WALKIN_STATUS[number];

export const RESERVATION_STATUS = [
  'R::PENDING',
  'R::BOOKED',
  'R::CONFIRMED',
  'R::LATE',
  'R::SEATED',
  'R::BILL',
  'R::OVERSTAYED',
  'R::NO_SHOW',
  'R::COMPLETED',
  'R::CANCELLED',
  'R::EXPIRED',
] as const;
export type ReservationStatus = typeof RESERVATION_STATUS[number];

export const RESERVATION_STATUS_MAP: Record<
  ReservationStatus,
  { apiRsrvStatus?: ApiRsrvStatus; apiTableStatus?: ApiTableStatus }
> = {
  'R::PENDING': { apiRsrvStatus: 'PENDING' },
  'R::BOOKED': { apiRsrvStatus: 'BOOKED' },
  'R::CONFIRMED': { apiRsrvStatus: 'CONFIRMED' },
  'R::LATE': { apiRsrvStatus: 'LATE' },
  'R::SEATED': { apiRsrvStatus: 'SEATED', apiTableStatus: 'IN_USE' },
  'R::BILL': { apiRsrvStatus: 'SEATED', apiTableStatus: 'BILL_REQUESTED' },
  'R::OVERSTAYED': { apiRsrvStatus: 'SEATED', apiTableStatus: 'OVERSTAY' },
  'R::NO_SHOW': { apiRsrvStatus: 'NO_SHOW' },
  'R::COMPLETED': { apiRsrvStatus: 'COMPLETED' },
  'R::CANCELLED': { apiRsrvStatus: 'CANCELLED' },
  'R::EXPIRED': { apiRsrvStatus: 'EXPIRED' },
};

export const RESRV_SRC = [
  'google',
  'restaurant',
  'customer',
  'walkIn',
] as const;
export type ResrvSrc = typeof RESRV_SRC[number];

export const API_RSRV_STATUS_MAP = {
  BOOKED: 1,
  SEATED: 2,
  COMPLETED: 3,
  CANCELLED: 4,
  NO_SHOW: 5,
  CONFIRMED: 6,
  LATE: 7,
  PENDING: 8,
  EXPIRED: 9,
} as const;
export type ApiRsrvStatus = keyof typeof API_RSRV_STATUS_MAP;
export type ApiRsrvStatusInt = ValueOf<typeof API_RSRV_STATUS_MAP>;

export const API_TABLE_STATUS = [
  'AVAILABLE',
  'RESERVED',
  'IN_USE',
  'BILL_REQUESTED',
  'REQUIRES_CLEANING',
  'BLOCKED',
  'OVERSTAY',
] as const;
export type ApiTableStatus = typeof API_TABLE_STATUS[number];

export const API_TABLE_STATUS_MAP: Record<ApiTableStatus, ApiTableStatusInt> = {
  AVAILABLE: 1,
  RESERVED: 2,
  IN_USE: 3,
  BILL_REQUESTED: 4,
  REQUIRES_CLEANING: 5,
  BLOCKED: 6,
  OVERSTAY: 7,
};
export type ApiTableStatusInt = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const EDITABLE_RESERVATION_FIELDS = [
  'TICKET_TYPE',
  '#_OF_ADULTS',
  '#_OF_CHILDREN',
  'DATE',
  'TIME',
  'DINING_INTERVAL',
  'TABLE',
  'OCCASION',
  'SPECIAL_REQUEST_BY_DINER',
  'RESERVATION_NOTES_BY_STAFF',
  'ASSOCIATED_CUSTOMER',
  'SALUTATION',
  'FIRST_NAME',
  'LAST_NAME',
  'CONTACT_NO',
  'EMAIL',
  'NOTES_ON_DINER',
  'RESERVATION_CONFIRMATION',
  'RESERVATION_REMINDER',
  'ALLOW_EMAIL_NOTIFY',
  'ALLOW_SMS_NOTIFY',
  'PAYMENT_TYPE',
] as const;
export type EditableReservationField =
  typeof EDITABLE_RESERVATION_FIELDS[number];

export const PAID_TICKET_EDITABLE_FIELDS: Record<
  ReservationStatus,
  EditableReservationField[]
> = {
  'R::PENDING': [
    'DINING_INTERVAL',
    'TABLE',
    'OCCASION',
    'SPECIAL_REQUEST_BY_DINER',
    'RESERVATION_NOTES_BY_STAFF',
    'NOTES_ON_DINER',
  ],
  'R::BOOKED': [
    'DATE',
    'TIME',
    'DINING_INTERVAL',
    'TABLE',
    'OCCASION',
    'SPECIAL_REQUEST_BY_DINER',
    'RESERVATION_NOTES_BY_STAFF',
    'ASSOCIATED_CUSTOMER',
    'SALUTATION',
    'FIRST_NAME',
    'LAST_NAME',
    'CONTACT_NO',
    'EMAIL',
    'NOTES_ON_DINER',
  ],
  'R::CONFIRMED': [
    'DATE',
    'TIME',
    'DINING_INTERVAL',
    'TABLE',
    'OCCASION',
    'SPECIAL_REQUEST_BY_DINER',
    'RESERVATION_NOTES_BY_STAFF',
    'ASSOCIATED_CUSTOMER',
    'SALUTATION',
    'FIRST_NAME',
    'LAST_NAME',
    'CONTACT_NO',
    'EMAIL',
    'NOTES_ON_DINER',
  ],
  'R::SEATED': [
    'DINING_INTERVAL',
    'TABLE',
    'OCCASION',
    'SPECIAL_REQUEST_BY_DINER',
    'RESERVATION_NOTES_BY_STAFF',
    'ASSOCIATED_CUSTOMER',
    'SALUTATION',
    'FIRST_NAME',
    'LAST_NAME',
    'CONTACT_NO',
    'EMAIL',
    'NOTES_ON_DINER',
  ],
  'R::BILL': [
    'DINING_INTERVAL',
    'TABLE',
    'OCCASION',
    'SPECIAL_REQUEST_BY_DINER',
    'RESERVATION_NOTES_BY_STAFF',
    'ASSOCIATED_CUSTOMER',
    'SALUTATION',
    'FIRST_NAME',
    'LAST_NAME',
    'CONTACT_NO',
    'EMAIL',
    'NOTES_ON_DINER',
  ],
  'R::COMPLETED': [],
  'R::OVERSTAYED': [
    'DINING_INTERVAL',
    'TABLE',
    'OCCASION',
    'SPECIAL_REQUEST_BY_DINER',
    'RESERVATION_NOTES_BY_STAFF',
    'ASSOCIATED_CUSTOMER',
    'SALUTATION',
    'FIRST_NAME',
    'LAST_NAME',
    'CONTACT_NO',
    'EMAIL',
    'NOTES_ON_DINER',
  ],
  'R::LATE': [
    'DATE',
    'TIME',
    'DINING_INTERVAL',
    'TABLE',
    'OCCASION',
    'SPECIAL_REQUEST_BY_DINER',
    'RESERVATION_NOTES_BY_STAFF',
    'ASSOCIATED_CUSTOMER',
    'SALUTATION',
    'FIRST_NAME',
    'LAST_NAME',
    'CONTACT_NO',
    'EMAIL',
    'NOTES_ON_DINER',
  ],
  'R::NO_SHOW': [
    'DATE',
    'TIME',
    'DINING_INTERVAL',
    'TABLE',
    'OCCASION',
    'SPECIAL_REQUEST_BY_DINER',
    'RESERVATION_NOTES_BY_STAFF',
    'ASSOCIATED_CUSTOMER',
    'SALUTATION',
    'FIRST_NAME',
    'LAST_NAME',
    'CONTACT_NO',
    'EMAIL',
    'NOTES_ON_DINER',
  ],
  'R::CANCELLED': [],
  'R::EXPIRED': [],
};

export const NO_PAYMENT_TICKET_EDITABLE_FIELDS: Record<
  ReservationStatus,
  EditableReservationField[]
> = {
  'R::PENDING': [],
  'R::BOOKED': [
    'TICKET_TYPE',
    '#_OF_ADULTS',
    '#_OF_CHILDREN',
    'DATE',
    'TIME',
    'DINING_INTERVAL',
    'TABLE',
    'OCCASION',
    'SPECIAL_REQUEST_BY_DINER',
    'RESERVATION_NOTES_BY_STAFF',
    'ASSOCIATED_CUSTOMER',
    'SALUTATION',
    'FIRST_NAME',
    'LAST_NAME',
    'CONTACT_NO',
    'EMAIL',
    'NOTES_ON_DINER',
  ],
  'R::CONFIRMED': [
    'TICKET_TYPE',
    '#_OF_ADULTS',
    '#_OF_CHILDREN',
    'DATE',
    'TIME',
    'DINING_INTERVAL',
    'TABLE',
    'OCCASION',
    'SPECIAL_REQUEST_BY_DINER',
    'RESERVATION_NOTES_BY_STAFF',
    'ASSOCIATED_CUSTOMER',
    'SALUTATION',
    'FIRST_NAME',
    'LAST_NAME',
    'CONTACT_NO',
    'EMAIL',
    'NOTES_ON_DINER',
  ],
  'R::SEATED': [
    'TICKET_TYPE',
    '#_OF_ADULTS',
    '#_OF_CHILDREN',
    'DINING_INTERVAL',
    'TABLE',
    'OCCASION',
    'SPECIAL_REQUEST_BY_DINER',
    'RESERVATION_NOTES_BY_STAFF',
    'ASSOCIATED_CUSTOMER',
    'SALUTATION',
    'FIRST_NAME',
    'LAST_NAME',
    'CONTACT_NO',
    'EMAIL',
    'NOTES_ON_DINER',
  ],
  'R::BILL': [
    'TICKET_TYPE',
    '#_OF_ADULTS',
    '#_OF_CHILDREN',
    'DINING_INTERVAL',
    'TABLE',
    'OCCASION',
    'SPECIAL_REQUEST_BY_DINER',
    'RESERVATION_NOTES_BY_STAFF',
    'ASSOCIATED_CUSTOMER',
    'SALUTATION',
    'FIRST_NAME',
    'LAST_NAME',
    'CONTACT_NO',
    'EMAIL',
    'NOTES_ON_DINER',
  ],
  'R::COMPLETED': [],
  'R::OVERSTAYED': [
    'TICKET_TYPE',
    '#_OF_ADULTS',
    '#_OF_CHILDREN',
    'DINING_INTERVAL',
    'TABLE',
    'OCCASION',
    'SPECIAL_REQUEST_BY_DINER',
    'RESERVATION_NOTES_BY_STAFF',
    'ASSOCIATED_CUSTOMER',
    'SALUTATION',
    'FIRST_NAME',
    'LAST_NAME',
    'CONTACT_NO',
    'EMAIL',
    'NOTES_ON_DINER',
  ],
  'R::LATE': [
    'TICKET_TYPE',
    '#_OF_ADULTS',
    '#_OF_CHILDREN',
    'DATE',
    'TIME',
    'DINING_INTERVAL',
    'TABLE',
    'OCCASION',
    'SPECIAL_REQUEST_BY_DINER',
    'RESERVATION_NOTES_BY_STAFF',
    'ASSOCIATED_CUSTOMER',
    'SALUTATION',
    'FIRST_NAME',
    'LAST_NAME',
    'CONTACT_NO',
    'EMAIL',
    'NOTES_ON_DINER',
  ],
  'R::NO_SHOW': [
    'TICKET_TYPE',
    '#_OF_ADULTS',
    '#_OF_CHILDREN',
    'DATE',
    'TIME',
    'DINING_INTERVAL',
    'TABLE',
    'OCCASION',
    'SPECIAL_REQUEST_BY_DINER',
    'RESERVATION_NOTES_BY_STAFF',
    'ASSOCIATED_CUSTOMER',
    'SALUTATION',
    'FIRST_NAME',
    'LAST_NAME',
    'CONTACT_NO',
    'EMAIL',
    'NOTES_ON_DINER',
  ],
  'R::CANCELLED': [],
  'R::EXPIRED': [],
};

export const WALKIN_TICKET_EDITABLE_FIELDS: Record<
  WalkinStatus,
  EditableReservationField[]
> = {
  'R::SEATED': [
    '#_OF_ADULTS',
    '#_OF_CHILDREN',
    'DINING_INTERVAL',
    'TABLE',
    'OCCASION',
    'SPECIAL_REQUEST_BY_DINER',
    'RESERVATION_NOTES_BY_STAFF',
    'ASSOCIATED_CUSTOMER',
    'SALUTATION',
    'FIRST_NAME',
    'LAST_NAME',
    'CONTACT_NO',
    'EMAIL',
    'NOTES_ON_DINER',
  ],
  'R::BILL': [
    '#_OF_ADULTS',
    '#_OF_CHILDREN',
    'DINING_INTERVAL',
    'TABLE',
    'OCCASION',
    'SPECIAL_REQUEST_BY_DINER',
    'RESERVATION_NOTES_BY_STAFF',
    'ASSOCIATED_CUSTOMER',
    'SALUTATION',
    'FIRST_NAME',
    'LAST_NAME',
    'CONTACT_NO',
    'EMAIL',
    'NOTES_ON_DINER',
  ],
  'R::COMPLETED': [],
  'R::OVERSTAYED': [
    '#_OF_ADULTS',
    '#_OF_CHILDREN',
    'DINING_INTERVAL',
    'TABLE',
    'OCCASION',
    'SPECIAL_REQUEST_BY_DINER',
    'RESERVATION_NOTES_BY_STAFF',
    'ASSOCIATED_CUSTOMER',
    'SALUTATION',
    'FIRST_NAME',
    'LAST_NAME',
    'CONTACT_NO',
    'EMAIL',
    'NOTES_ON_DINER',
  ],
};

export const TIME_STATE = ['PAST', 'TODAY', 'FUTURE'] as const;
export type TimeState = typeof TIME_STATE[number];

const QUICK_ACTIONS_TYPES = [
  'A::CANCEL',
  'A::BOOK',
  'A::CONFIRM',
  'A::SEAT',
  'A::DEPART',
  'A::MARK_NOSHOW',
  'A::EDIT',
] as const;
type QuickActionType = typeof QUICK_ACTIONS_TYPES[number];
export type QuickAction = {
  type: QuickActionType;
  isDisabled?: (reservation: ReservationItem | null) => boolean;
};

/**
 * @todo TODO: only allow switching from PENDING to BOOKED for manual booking, payment outside of Oddle
 * https://oddle-pm.atlassian.net/wiki/spaces/OTR/pages/2929164289/Reservation+Status+Flow+Editable+fields+in+v3#:~:text=Booked%20(only%20for%20manual%20booking%2C%20payment%20outside%20of%20Oddle)
 */
const isManualBookingWithOutsidePayment = (
  reservation: Partial<ReservationItem>
) => {
  const paymentRequest = reservation.reservationPaymentRequest;
  if (!paymentRequest) return null;

  switch (paymentRequest.type) {
    case 'ONLINE_PAYMENT_LINK':
      return false;
    case 'OTHER_METHOD':
      return true;
    default:
      return null;
  }
};

export const TODAY_ALLOWABLE_ACTIONS: Record<
  ReservationStatus,
  QuickAction[] | null
> = {
  'R::PENDING': [
    { type: 'A::CANCEL' },
    {
      type: 'A::BOOK',
      isDisabled: (rItem) =>
        rItem ? !isManualBookingWithOutsidePayment(rItem) : false,
    },
    { type: 'A::EDIT' },
  ],
  'R::BOOKED': [
    { type: 'A::CONFIRM' },
    { type: 'A::SEAT' },
    { type: 'A::DEPART', isDisabled: () => true },
    { type: 'A::EDIT' },
  ],
  'R::CONFIRMED': [
    { type: 'A::CONFIRM', isDisabled: () => true },
    { type: 'A::SEAT' },
    { type: 'A::DEPART', isDisabled: () => true },
    { type: 'A::EDIT' },
  ],
  'R::LATE': [
    { type: 'A::MARK_NOSHOW' },
    { type: 'A::SEAT' },
    { type: 'A::DEPART', isDisabled: () => true },
    { type: 'A::EDIT' },
  ],
  'R::SEATED': [
    { type: 'A::CONFIRM', isDisabled: () => true },
    { type: 'A::SEAT', isDisabled: () => true },
    { type: 'A::DEPART' },
    { type: 'A::EDIT' },
  ],
  'R::BILL': [
    { type: 'A::CONFIRM', isDisabled: () => true },
    { type: 'A::SEAT', isDisabled: () => true },
    { type: 'A::DEPART' },
    { type: 'A::EDIT' },
  ],
  'R::OVERSTAYED': [
    { type: 'A::CONFIRM', isDisabled: () => true },
    { type: 'A::SEAT', isDisabled: () => true },
    { type: 'A::DEPART' },
    { type: 'A::EDIT' },
  ],
  'R::COMPLETED': [
    { type: 'A::CONFIRM', isDisabled: () => true },
    { type: 'A::SEAT', isDisabled: () => true },
    { type: 'A::DEPART', isDisabled: () => true },
    { type: 'A::EDIT', isDisabled: () => true },
  ],
  'R::CANCELLED': [
    { type: 'A::CONFIRM', isDisabled: () => true },
    { type: 'A::SEAT', isDisabled: () => true },
    { type: 'A::DEPART', isDisabled: () => true },
    { type: 'A::EDIT', isDisabled: () => true },
  ],
  'R::NO_SHOW': [
    { type: 'A::CONFIRM', isDisabled: () => true },
    { type: 'A::SEAT', isDisabled: () => true },
    { type: 'A::DEPART', isDisabled: () => true },
    { type: 'A::EDIT' },
  ],
  'R::EXPIRED': null,
};
export const FUTURE_ALLOWABLE_ACTIONS: Record<
  ReservationStatus,
  QuickAction[] | null
> = {
  'R::PENDING': [
    { type: 'A::CANCEL' },
    {
      type: 'A::BOOK',
      isDisabled: (rItem) =>
        rItem ? !isManualBookingWithOutsidePayment(rItem) : false,
    },
    { type: 'A::EDIT' },
  ],
  'R::BOOKED': [
    { type: 'A::CONFIRM' },
    { type: 'A::SEAT', isDisabled: () => true },
    { type: 'A::DEPART', isDisabled: () => true },
    { type: 'A::EDIT' },
  ],
  'R::CONFIRMED': [
    { type: 'A::CONFIRM', isDisabled: () => true },
    { type: 'A::SEAT', isDisabled: () => true },
    { type: 'A::DEPART', isDisabled: () => true },
    { type: 'A::EDIT' },
  ],
  'R::CANCELLED': [
    { type: 'A::CONFIRM', isDisabled: () => true },
    { type: 'A::SEAT', isDisabled: () => true },
    { type: 'A::DEPART', isDisabled: () => true },
    { type: 'A::EDIT', isDisabled: () => true },
  ],
  'R::LATE': null,
  'R::SEATED': null,
  'R::BILL': null,
  'R::OVERSTAYED': null,
  'R::COMPLETED': null,
  'R::NO_SHOW': null,
  'R::EXPIRED': null,
};
export const PAST_ALLOWABLE_ACTIONS: Record<
  ReservationStatus,
  QuickAction[] | null
> = {
  'R::PENDING': null,
  'R::COMPLETED': [
    { type: 'A::CONFIRM', isDisabled: () => true },
    { type: 'A::SEAT', isDisabled: () => true },
    { type: 'A::DEPART', isDisabled: () => true },
    { type: 'A::EDIT', isDisabled: () => true },
  ],
  'R::CANCELLED': [
    { type: 'A::CONFIRM', isDisabled: () => true },
    { type: 'A::SEAT', isDisabled: () => true },
    { type: 'A::DEPART', isDisabled: () => true },
    { type: 'A::EDIT', isDisabled: () => true },
  ],
  'R::NO_SHOW': [
    { type: 'A::CONFIRM', isDisabled: () => true },
    { type: 'A::SEAT', isDisabled: () => true },
    { type: 'A::DEPART', isDisabled: () => true },
    { type: 'A::EDIT', isDisabled: () => true },
  ],
  'R::BOOKED': null,
  'R::CONFIRMED': null,
  'R::LATE': null,
  'R::SEATED': null,
  'R::BILL': null,
  'R::OVERSTAYED': null,
  'R::EXPIRED': null,
};
export const ALLOWABLE_ACTIONS_BY_TIME: Record<
  TimeState,
  typeof TODAY_ALLOWABLE_ACTIONS
> = {
  TODAY: TODAY_ALLOWABLE_ACTIONS,
  FUTURE: FUTURE_ALLOWABLE_ACTIONS,
  PAST: PAST_ALLOWABLE_ACTIONS,
};

export const EDITABLE_BY_TIME: Record<TimeState, ReservationStatus[]> = {
  TODAY: [
    'R::PENDING',
    'R::BOOKED',
    'R::CONFIRMED',
    'R::LATE',
    'R::SEATED',
    'R::BILL',
    'R::OVERSTAYED',
    'R::NO_SHOW',
  ],
  FUTURE: ['R::PENDING', 'R::BOOKED', 'R::CONFIRMED'],
  PAST: [],
};

export const allowableTransitions = (
  reservation: Partial<ReservationItem>
): ReservationStatus[] | null => {
  if (!reservation.rStatus) return null;

  const todayTransitions: Record<
    ReservationStatus,
    ReservationStatus[] | null
  > = {
    'R::PENDING': (
      [
        'R::CANCELLED',
        isManualBookingWithOutsidePayment(reservation) && 'R::BOOKED',
      ] as const
    ).filter(Boolean),
    'R::BOOKED': ['R::CONFIRMED', 'R::SEATED', 'R::CANCELLED', 'R::NO_SHOW'],
    'R::CONFIRMED': ['R::BOOKED', 'R::SEATED', 'R::CANCELLED', 'R::NO_SHOW'],
    'R::LATE': [
      'R::BOOKED',
      'R::CONFIRMED',
      'R::SEATED',
      'R::CANCELLED',
      'R::NO_SHOW',
    ],
    'R::SEATED': [
      'R::BOOKED',
      'R::CONFIRMED',
      'R::BILL',
      'R::COMPLETED',
      'R::CANCELLED',
      'R::NO_SHOW',
    ],
    'R::BILL': [
      'R::BOOKED',
      'R::CONFIRMED',
      'R::SEATED',
      'R::COMPLETED',
      'R::CANCELLED',
      'R::NO_SHOW',
    ],
    'R::OVERSTAYED': [
      'R::BOOKED',
      'R::CONFIRMED',
      'R::BILL',
      'R::COMPLETED',
      'R::CANCELLED',
      'R::NO_SHOW',
    ],
    'R::COMPLETED': ['R::SEATED', 'R::CANCELLED', 'R::NO_SHOW'],
    'R::CANCELLED': [],
    'R::NO_SHOW': [
      'R::BOOKED',
      'R::CONFIRMED',
      'R::SEATED',
      'R::CANCELLED',
      'R::COMPLETED',
    ],
    'R::EXPIRED': null,
  };
  const futureTransitions: Record<
    ReservationStatus,
    ReservationStatus[] | null
  > = {
    'R::PENDING': (
      [
        'R::CANCELLED',
        isManualBookingWithOutsidePayment(reservation) && 'R::BOOKED',
      ] as const
    ).filter(Boolean),
    'R::BOOKED': ['R::CONFIRMED', 'R::CANCELLED'],
    'R::CONFIRMED': ['R::BOOKED', 'R::CANCELLED'],
    'R::CANCELLED': [],
    'R::LATE': null,
    'R::SEATED': null,
    'R::BILL': null,
    'R::OVERSTAYED': null,
    'R::COMPLETED': null,
    'R::NO_SHOW': null,
    'R::EXPIRED': null,
  };
  const pastTransitions: Record<ReservationStatus, ReservationStatus[] | null> =
    {
      'R::PENDING': null,
      'R::COMPLETED': ['R::CANCELLED', 'R::NO_SHOW'],
      'R::CANCELLED': [],
      'R::NO_SHOW': ['R::COMPLETED', 'R::CANCELLED'],
      'R::BOOKED': null,
      'R::CONFIRMED': null,
      'R::LATE': null,
      'R::SEATED': null,
      'R::BILL': null,
      'R::OVERSTAYED': null,
      'R::EXPIRED': null,
    };

  const transitions =
    reservation.rTimeState === 'TODAY'
      ? todayTransitions[reservation.rStatus]
      : reservation.rTimeState === 'FUTURE'
      ? futureTransitions[reservation.rStatus]
      : reservation.rTimeState === 'PAST'
      ? pastTransitions[reservation.rStatus]
      : null;

  if (reservation.source === 'walkIn' && transitions)
    return WALKIN_STATUS.filter((s) => transitions.includes(s));

  return transitions;
};
