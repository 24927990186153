import { pxToRem, responsiveFontSizes } from '../utils/getFontValue';

// ----------------------------------------------------------------------

declare module '@mui/material/styles' {
  interface TypographyVariants {
    tableRow: React.CSSProperties;
    captionSemibold: React.CSSProperties;
    caption2: React.CSSProperties;
    caption2Bold: React.CSSProperties;
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    tableRow?: React.CSSProperties;
    captionSemibold?: React.CSSProperties;
    caption2?: React.CSSProperties;
    caption2Bold?: React.CSSProperties;
  }
}
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    tableRow: true;
    captionSemibold: true;
    caption2: true;
    caption2Bold: true;
  }
}

const FONT_PRIMARY = 'Public Sans, system';

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 600,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40), // xs
    letterSpacing: 2,
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    fontWeight: 600,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32), // xs
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontWeight: 600,
    lineHeight: 48 / 32,
    fontSize: pxToRem(24), // xs
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontWeight: 600,
    lineHeight: 36 / 24,
    fontSize: pxToRem(20), // xs
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },
  h5: {
    fontWeight: 600,
    lineHeight: 30 / 20,
    fontSize: pxToRem(18), // xs
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontWeight: 600,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17), // xs
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 24 / 16,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    fontWeight: 500,
    lineHeight: 24 / 16,
    fontSize: pxToRem(16),
  },
  body2: {
    fontWeight: 500,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  tableRow: {
    fontWeight: 500,
    lineHeight: 20 / 13,
    fontSize: pxToRem(13),
  },
  caption: {
    fontWeight: 500,
    lineHeight: 18 / 12,
    fontSize: pxToRem(12),
  },
  captionSemibold: {
    fontWeight: 600,
    lineHeight: 18 / 12,
    fontSize: pxToRem(12),
  },
  caption2: {
    fontWeight: 500,
    lineHeight: 16 / 10,
    fontSize: pxToRem(10),
  },
  caption2Bold: {
    fontWeight: 700,
    lineHeight: 15 / 10,
    fontSize: pxToRem(10),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 18 / 12,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'none',
  },
} as const;

export default typography;
