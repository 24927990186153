export const BACKGROUND_COLOUR = '#e8e6f2';
export const MAIN_COLOUR = '#938cda';
export const DARK_COLOUR = '#3D0099';

export const TRACK_WIDTH = 150;
export const trackHeight = TRACK_WIDTH / 1.51;
export const thumbDimensions = trackHeight * 0.8;
export const thumbMargins = (trackHeight * 0.2) / 2;
export const switchThumbInnerDimensions = thumbDimensions * 0.4;
export const progressBarDimensions = thumbDimensions + thumbMargins * 2;

export const TRACK_SHRINK_DURATION = 0.5;
export const TRACK_COLOUR_CHANGE_DURATION = 0.5;
export const TRACK_EXPAND_DURATION = 0.2;
export const SVG_CIRCLE_HIDE_DURATION = 0.01;
export const SWITCH_EXPAND_DURATION = 0.2;
export const SWITCH_COLOUR_CHANGE_DURATION = 0.5;
export const EXIT_LASTING_DURATION = 0.1;
