import { styled } from '@mui/material/styles';
import { m as motion, Variants } from 'framer-motion';

import {
  BACKGROUND_COLOUR,
  DARK_COLOUR,
  MAIN_COLOUR,
  SVG_CIRCLE_HIDE_DURATION,
  SWITCH_COLOUR_CHANGE_DURATION,
  SWITCH_EXPAND_DURATION,
  switchThumbInnerDimensions,
  TRACK_EXPAND_DURATION,
  TRACK_SHRINK_DURATION,
} from './constants';
import { calculateTransitionVariants } from './utils';

export const switchThumbInnerVariants: Variants = {
  scaleUp: {
    scale: 1,
    transition: {
      delay: SVG_CIRCLE_HIDE_DURATION + TRACK_EXPAND_DURATION,
      duration: SWITCH_EXPAND_DURATION,
    },
    backgroundColor: DARK_COLOUR,
  },
  scaleUpAndChangeColor: {
    scale: [null, 1, 1],
    backgroundColor: [null, MAIN_COLOUR, DARK_COLOUR],
  },
  scaleDown: {
    scale: 0,
    transition: { delay: TRACK_SHRINK_DURATION, duration: 0.5 },
  },
  changeColorForward: {
    backgroundColor: DARK_COLOUR,
    transition: {
      delay: 0,
      duration: 0.5,
      repeat: Number.POSITIVE_INFINITY,
      repeatType: 'reverse',
    },
  },
  changeColorBackward: {
    backgroundColor: BACKGROUND_COLOUR,
    transition: { delay: 0, duration: 0.5 },
  },
};

const { times, duration } = calculateTransitionVariants(
  SWITCH_EXPAND_DURATION,
  SWITCH_COLOUR_CHANGE_DURATION
);

export const switchThumbInnerTransition = {
  type: 'easeOut',
  delay: SVG_CIRCLE_HIDE_DURATION + TRACK_EXPAND_DURATION,
  duration,
  times,
};

const SwitchThumbInner = styled(motion.div)({
  height: switchThumbInnerDimensions,
  width: switchThumbInnerDimensions,
  borderRadius: '50%',
  backgroundColor: BACKGROUND_COLOUR,
});

export default SwitchThumbInner;
