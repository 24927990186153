import { z } from 'zod';

export const ZTableBlockItemPayload = z.object({
  id: z.string(),
  date: z.string(),
  from: z.string(),
  to: z.string(),
});

export type TableBlockItemPayload = z.infer<typeof ZTableBlockItemPayload>;

export const ZTableBlockPayload = z.object({
  blockIndefinitely: z.boolean().optional(),
  blockTemporarily: z.boolean().optional(),
  blocks: z.array(ZTableBlockItemPayload).optional(),
  date: z.string().optional(),
});

export type TableBlockPayload = z.infer<typeof ZTableBlockPayload>;

export const ZTableBlock = z.object({
  id: z.string(),
  date: z.string(),
  entireDay: z.number().nullable(),
  outletId: z.string(),
  tableId: z.string(),
  from: z.string(),
  to: z.string(),
});

export type TableBlock = z.infer<typeof ZTableBlock>;
