import useV2Api from '@/common/hooks/use-v2-api';
import { env } from '@/env.mjs';
import { useSession } from '@/feat/auth/context';
import { MagicBellProvider, useConfig } from '@magicbell/react-headless';
import { prefetchConfig, registerServiceWorker } from '@magicbell/webpush';
import { detectIncognito } from 'detectincognitojs';
import { useRouter } from 'next/router';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  browserName,
  deviceType,
  osName,
  osVersion,
} from 'react-device-detect';
import subscriptionManager, {
  SubscriptionManager,
} from './subscription-manager-service';

export type DeviceInfo = {
  standalone: boolean;
  browserName: string;
  osName: string;
  deviceType: string;
  isPrivate: boolean;
  osVersion: string;
  notificationApiPermissionStatus: string;
  serviceWorkerStatus: string;
  subscriptionState: 'pending' | 'subscribed' | 'unsubscribed';
  topics: string[];
};

export type State =
  | { status: 'idle' | 'busy' | 'success' }
  | { status: 'error'; error: string }
  | { status: 'unsupported' };

interface MagicBellContextType {
  state: State;
  info: DeviceInfo | null;
  subscribe: () => void;
  unsubscribe: () => void;
}

const MagicBellContext = createContext<MagicBellContextType>({
  state: { status: 'idle' },
  subscribe: () => {},
  unsubscribe: () => {},
  info: null,
});

export const MagicBellContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const [state, setState] = useState<State>({ status: 'idle' });
  const [enabledStores, setEnabledStores] = useState<string[]>([]);
  const [info, setInfo] = useState<DeviceInfo | null>(null);
  const config = useConfig();
  const v2Api = useV2Api();
  const { data: sessionData } = useSession();
  const omsOutletId = sessionData?.outlet?.omsOutletId;
  const userEmail = sessionData?.userInfo?.email;
  const outletId = sessionData?.outlet?.id;
  const magicBellExternalId = omsOutletId || userEmail || '';

  const subscribeOptions = useMemo(() => {
    const host = 'https://api.magicbell.com';
    try {
      const url = new URL(config.channels?.webPush.config.subscribeUrl || '');
      return {
        token: url.searchParams.get('access_token') || '',
        project: url.searchParams.get('project') || '',
        host,
        serviceWorkerPath: '/service-worker.min.js',
      };
    } catch (e) {
      return { token: '', project: '', host };
    }
  }, [config]);

  const subscribe = useCallback(async () => {
    try {
      setState({ status: 'busy' });
      await subscriptionManager.subscribe(
        omsOutletId || userEmail || '',
        subscribeOptions
      );
      setState({ status: 'success' });
      if (sessionData && sessionData.outlet?.omsOutletId) {
        await v2Api.post(
          `/outlets/trigger-notification`,
          sessionData.userInfo?.email
            ? { email: sessionData.userInfo.email }
            : {
                externalId: sessionData.outlet.omsOutletId,
              }
        );
      }
      if (outletId) {
        const newEnabledStores = Array.from(
          new Set([...enabledStores, outletId])
        );
        SubscriptionManager.saveEnabledStores(newEnabledStores);
        setEnabledStores(newEnabledStores);
      }
    } catch (error: any) {
      setState({ status: 'error', error: error.message });
    }
  }, [
    enabledStores,
    omsOutletId,
    outletId,
    sessionData,
    subscribeOptions,
    userEmail,
    v2Api,
  ]);

  const unsubscribe = useCallback(async () => {
    await subscriptionManager.unsubscribe();
    if (outletId) {
      const newEnabledStores = enabledStores.filter(
        (store) => store !== outletId
      );
      SubscriptionManager.saveEnabledStores(newEnabledStores);
      setEnabledStores(newEnabledStores);
      subscriptionManager.subscribeToActiveSubscriptionFromLocalForage(
        magicBellExternalId,
        async (_, context) => {
          setInfo((info) =>
            info
              ? {
                  ...info,
                  notificationApiPermissionStatus:
                    typeof Notification !== 'undefined'
                      ? Notification.permission
                      : 'Notification API unsupported',
                  serviceWorkerStatus:
                    context.serviceWorkerRegistration?.active?.state ||
                    'inactive',
                  subscriptionState: 'unsubscribed',
                }
              : null
          );
        }
      )();
    }
    setState({ status: 'idle' });
  }, [enabledStores, magicBellExternalId, outletId]);

  useEffect(() => {
    if (!subscribeOptions.token) {
      return;
    }
    registerServiceWorker({ path: '/service-worker.min.js' });
    prefetchConfig(subscribeOptions);
  }, [subscribeOptions]);

  useEffect(() => {
    SubscriptionManager.saveWebpushLanguage(router.locale || '');
  }, [router.locale]);

  useEffect(() => {
    SubscriptionManager.getEnabledStores().then((stores) => {
      if (stores) {
        setEnabledStores(stores);
      }
    });
  }, []);

  useEffect(() => {
    if (!magicBellExternalId) {
      return;
    }
    // initialize device info
    setInfo({
      standalone: window.matchMedia('(display-mode: standalone)').matches, // true if PWA is installed
      browserName,
      osName,
      deviceType,
      osVersion,
      isPrivate: false,
      // note that user may still not have granted notification permissions on a system settings level
      notificationApiPermissionStatus:
        typeof Notification !== 'undefined'
          ? Notification.permission
          : 'Notification API unsupported',
      serviceWorkerStatus: 'fetching',
      subscriptionState: 'pending',
      topics: [],
    });

    return subscriptionManager.subscribeToActiveSubscriptionFromLocalForage(
      magicBellExternalId,
      async (activeSubscription, context) => {
        const { isPrivate } = await detectIncognito();
        setInfo((info) =>
          info
            ? {
                ...info,
                isPrivate,
                serviceWorkerStatus:
                  context.serviceWorkerRegistration?.active?.state ||
                  'inactive',
                subscriptionState: Boolean(activeSubscription)
                  ? 'subscribed'
                  : 'unsubscribed',
              }
            : null
        );
      }
    );
  }, [magicBellExternalId]);

  if (!magicBellExternalId) {
    return <>{children}</>;
  }

  return (
    <MagicBellProvider
      apiKey={env.NEXT_PUBLIC_MAGIC_BELL_API_KEY}
      userExternalId={omsOutletId}
      userEmail={userEmail || undefined}
    >
      <MagicBellContext.Provider
        value={{ state, subscribe, unsubscribe, info }}
      >
        {children}
      </MagicBellContext.Provider>
    </MagicBellProvider>
  );
};
export const useMagicBellContext = () => {
  return useContext(MagicBellContext);
};
