import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useSession } from './context';
import { axiClient } from '@/common/lib/axios-clean-trace';

export default function useOutletSwitch() {
  const { refetch: refetchSession } = useSession();
  const { data } = useSession();
  const userInfo = data?.userInfo;
  const brands = React.useMemo(
    () => userInfo?.brands || [],
    [userInfo?.brands]
  );
  const {
    mutateAsync: switchOutlet,
    isLoading: isSwitching,
    error,
  } = useMutation(async (omsOutletId: string) => {
    await axiClient.post('/api/auth/switch-outlet', { omsOutletId });
    await refetchSession();
  });

  const selectedBrand = React.useMemo(() => {
    const brand = data?.brand;
    if (!brand) return null;
    // try to get `omsBrandId` from `brands` array since it's more accurate than `data.brand.omsBrandId`
    // `data.brand.omsBrandId` is manually mapped in Reserve's DB -> can be inaccurate
    const accurateOmsBrandId = brands?.find(
      (_brand) => _brand.id === brand.id
    )?.omsBrandId;
    if (!accurateOmsBrandId) return brand;
    return {
      ...brand,
      omsBrandId: accurateOmsBrandId,
    };
  }, [data?.brand, brands]);

  const selectedOutlet = React.useMemo(() => {
    const outlet = data?.outlet;
    if (!outlet) return null;
    // try to get `omsOutletId` from `brands` array since it's more accurate than `data.outlet.omsOutletId`
    // `data.outlet.omsOutletId` is manually mapped in Reserve's DB -> can be inaccurate
    const accurateOmsOutletId = brands
      ?.find((_brand) => _brand.id === data?.brand?.id)
      ?.outlets.find((_outlet) => _outlet.id === outlet.id)?.omsOutletId;
    if (!accurateOmsOutletId) return outlet;
    return { ...outlet, omsOutletId: accurateOmsOutletId };
  }, [data?.brand, brands, data?.outlet]);

  const isSwitchable = brands.flatMap((brand) => brand.outlets).length > 1;
  const countryCode = selectedBrand?.countryCode;
  const timezone = selectedBrand?.timezone;
  const userType: 'oddler' | 'merchant' = userInfo?.email?.includes('@oddle.me')
    ? 'oddler'
    : 'merchant';

  return {
    /**
     * user info - only exists when login via email
     */
    userInfo,
    /**
     * switchable brands - only exists when login via email
     */
    brands,
    switchOutlet,
    isSwitching,
    error,
    selectedOutlet,
    selectedBrand,
    isSwitchable,
    countryCode,
    timezone,
    userType,
  };
}
