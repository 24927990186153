import Router from 'next/router';
import { ReactNode } from 'react';
import {
  usePromptV3,
  useRedirectToV2AndLogin,
} from '../hooks/use-host-classic';
import useSession from '../hooks/use-session';
import LoadingScreen from '@/common/components/loading-screen/LoadingScreen';

const ignoredPaths: (string | ((router: typeof Router) => boolean))[] = [
  ({ pathname }) => pathname.startsWith('/__test'),
  ({ pathname }) => pathname.startsWith('/__private'),
  ({ pathname }) => pathname.startsWith('/login'),
  '/v3-prompt',
  '/v3-switch',
];

export function V3SwitchGuard({ children }: { children: ReactNode }) {
  const { data: sessionData } = useSession();
  const promptV3 = usePromptV3();
  const redirectToV2AndLogin = useRedirectToV2AndLogin();

  if (
    sessionData?.isOutletLoggedIn &&
    !sessionData?.brand?.routeToHostv3 &&
    ignoredPaths.every((it) =>
      typeof it === 'string' ? it !== Router.pathname : !it(Router)
    )
  ) {
    if (sessionData.brand?.offerHostv3) {
      promptV3();
    } else {
      redirectToV2AndLogin();
    }
    return <LoadingScreen />;
  }

  return <>{children}</>;
}
