import React from 'react';
import Router from 'next/router';

export default function usePrefetchRoutes(
  routes = [
    '/reservations',
    '/reservations/online-schedule',
    '/customers',
    '/customers/[id]',
    '/customers/add',
    '/customers/edit/[id]',
    '/settings',
    '/settings/automation',
    '/settings/automation/edit',
    '/settings/schedules/special',
    '/settings/schedules/weekly',
    '/settings/tickets',
    '/settings/tickets/add',
    '/settings/tickets/edit/[id]',
    '/settings/areas-combination',
    '/settings/areas',
    '/settings/language',
    '/settings/notifications',
    '/settings/booking-management',
    '/transactions',
    '/support',
    '/login',
    '/login/switch',
  ]
) {
  React.useEffect(() => {
    const prefetchRoutes = async () => {
      for (const route of routes) {
        await Router.prefetch(route).catch(console.warn);
      }
    };
    prefetchRoutes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
