import { z } from 'zod';

export const TypeWindowBooking = {
  MINUTES: 'minutes',
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
} as const;

export const ZServiceTimingWithoutRef = z.object({
  id: z.string(),
  outletId: z.string(),
  ticketId: z.string(),
  name: z.string(),
  isRecurring: z.boolean(),
  days: z.array(z.number()),
  dailyStartTime: z.string().nullable(),
  dailyEndTime: z.string().nullable(),
  recurringStartDate: z.string().nullable(),
  recurringEndDate: z.string().nullable(),
  leadTime: z.number(),
  gracePeriod: z.number(),
  diningInterval: z.number(),
  intervalTime: z.number(),
  specificStartTime: z.string().nullable(),
  specificEndTime: z.string().nullable(),
  specificDate: z.string().nullable(),
  specificDateArray: z.array(z.string()).nullable(),
  maxPaxPerReservation: z.number().nullable(),
  bookingOnline: z.boolean(),
  maxGuest: z.number().nullable().optional(),
  maxReservation: z.number().nullable(),
  minPartySize: z.number().nullable(),
  maxPartySize: z.number().nullable(),
  minTimeBooking: z.string().nullable(),
  minSpecificTimeBooking: z.string().nullable(),
  maxTimeBooking: z.string().nullable(),
  maxSpecificTimeBooking: z.string().nullable(),
  // TODO remove nullable in typeMinTimeBooking and typeMaxTimeBooking
  // Literally these fields cannot be null
  // still mark them nullable because of old data which is added before these fields are introduced
  typeMinTimeBooking: z.nativeEnum(TypeWindowBooking).nullable(),
  typeMaxTimeBooking: z.nativeEnum(TypeWindowBooking).nullable(),
  timeSlots: z.array(z.string()).nullable().optional(),
  showArea: z.boolean().nullable(),
  isAllArea: z.boolean().nullable(),
  customReservationPolicy: z.boolean().nullable(),
  restaurantPolicy: z.string().nullable(),
  cancellationPolicy: z.string().nullable().optional(),
  restaurantPolicyDefault: z.string().nullable(),
  cancellationPolicyDefault: z.string().nullable(),
  createdBy: z.string().nullable().optional(),
  modifiedBy: z.string().nullable().optional(),
  createdTimestamp: z.string(),
  modifiedTimestamp: z.string().nullable(),
  startTime: z.string().optional(),
  endTime: z.string().optional(),
  group: z.string().optional().nullable(),
});

export const ZBlockOutDateWithoutRef = z.object({
  id: z.string().optional(),
  createdTimestamp: z.string().nullable().optional(),
  modifiedTimestamp: z.string().nullable().optional(),
  date: z.string(),
  entireDay: z.boolean().optional(),
  name: z.string().nullable(),
  outletId: z.string(),
  reason: z.string(),
  showReason: z.boolean(),
  reasonStaffNotes: z.string().optional().nullish(),
  group: z.string().nullish(),
});

export const ZReservationPaymentRequest = z.object({
  createdTimestamp: z.string().optional().nullable(),
  expireDateTime: z.string().optional().nullable(),
  expireTime: z.number().optional().nullable(),
  id: z.string().optional().nullable(),
  link: z.string().optional().nullable(),
  modifiedTimestamp: z.string().optional().nullable(),
  notes: z.string().optional().nullable(),
  outletId: z.string().optional().nullable(),
  reservationId: z.string().optional().nullable(),
  status: z.string().optional().nullable(),
  type: z.string().optional().nullable(),
});
