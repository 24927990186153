// @ts-check

const { z } = require('zod');
const uniq = require('lodash/uniq');

const ZBcp47 = z.enum([
  'en-SG',
  'zh-SG',
  'en-MY',
  'zh-MY',
  'ms-MY',
  'en-HK',
  'zh-Hant-HK',
  'en-TW',
  'zh-Hant-TW',
]);
const ZSubPath = z.enum([
  'en_SG',
  'zh_SG',
  'en_MY',
  'zh_MY',
  'bm_MY',
  'en_HK',
  'zh_HK',
  'en_TW',
  'zh_TW',
]);
const ZLocale = z.object({
  'ISO 3166-2': z.string().length(2),
  /**
   * BCP 47 format (en, en-SG, zh-Hans-SG)
   */
  bcp47s: z.array(ZBcp47).min(1),
  /**
   * BCP 47 format (en, en-SG, zh-Hans-SG)
   */
  subPathMap: z.record(ZBcp47, ZSubPath),
  cPrefix: z.string().min(1),
  thousandSeparator: z.string(),
  decimalSeparator: z.string(),
});

/**
 * @type {z.infer<typeof ZLocale>[]}
 */
const locales = [
  {
    'ISO 3166-2': 'SG',
    bcp47s: ['en-SG', 'zh-SG'],
    subPathMap: {
      'en-SG': 'en_SG',
      'zh-SG': 'zh_SG',
    },
    cPrefix: 'S$',
    thousandSeparator: ',',
    decimalSeparator: '.',
  },
  {
    'ISO 3166-2': 'MY',
    bcp47s: ['en-MY', 'zh-MY', 'ms-MY'],
    subPathMap: {
      'en-MY': 'en_MY',
      'zh-MY': 'zh_MY',
      'ms-MY': 'bm_MY',
    },
    cPrefix: 'RM',
    thousandSeparator: ',',
    decimalSeparator: '.',
  },
  {
    'ISO 3166-2': 'HK',
    bcp47s: ['en-HK', 'zh-Hant-HK'],
    subPathMap: {
      'en-HK': 'en_HK',
      'zh-Hant-HK': 'zh_HK',
    },
    cPrefix: 'HK$',
    thousandSeparator: ',',
    decimalSeparator: '.',
  },
  {
    'ISO 3166-2': 'TW',
    bcp47s: ['en-TW', 'zh-Hant-TW'],
    subPathMap: {
      'en-TW': 'en_TW',
      'zh-Hant-TW': 'zh_TW',
    },
    cPrefix: 'NT$',
    thousandSeparator: ',',
    decimalSeparator: '.',
  },
];

const defaultLocale = ZLocale.parse(
  locales.find((loc) => loc['ISO 3166-2'] === 'SG')
);

/**
 * 'en-SG' <-> 'en_SG'
 * 'zh-Hant-HK' <-> 'zh_HK'
 * within the available host v3 locales
 */

const subPathToBcp47 = z
  .function()
  .args(z.unknown())
  .returns(ZBcp47.or(z.undefined()))
  .implement((str) => {
    const pRes = ZSubPath.safeParse(str);
    if (!pRes.success) return;
    const subPath = pRes.data;
    const invertedMaps = z
      .record(ZSubPath, ZBcp47)
      .parse(
        Object.fromEntries(
          locales.flatMap((loc) =>
            Object.entries(loc.subPathMap).map(([k, v]) => [v, k])
          )
        )
      );
    return invertedMaps[subPath];
  });

const bcp47ToSubPath = z
  .function()
  .args(z.unknown())
  .returns(ZSubPath.or(z.undefined()))
  .implement((str) => {
    const pRes = ZBcp47.safeParse(str);
    if (!pRes.success) return;
    const bcp47 = pRes.data;
    const maps = z
      .record(ZBcp47, ZSubPath)
      .parse(
        Object.fromEntries(
          locales.flatMap((loc) => Object.entries(loc.subPathMap))
        )
      );
    return maps[bcp47];
  });

const nextI18nLocales = uniq([
  'DEFAULT_LOCALE',
  ...locales.flatMap((loc) =>
    loc.bcp47s.flatMap((lng) => bcp47ToSubPath(lng) || 'DEFAULT_LOCALE')
  ),
]);
const allBcp47s = uniq(locales.flatMap((loc) => loc.bcp47s));

const isSimplifiedZh = z
  .function()
  .args(ZBcp47)
  .implement((bcp47) => ['zh-SG', 'zh-MY'].includes(bcp47));
const isTraditionalZh = z
  .function()
  .args(ZBcp47)
  .implement((bcp47) => ['zh-Hant-HK', 'zh-Hant-TW'].includes(bcp47));

module.exports = {
  ZBcp47,
  ZLocale,
  defaultLocale,
  locales: z.array(ZLocale).parse(locales),
  subPathToBcp47,
  bcp47ToSubPath,
  nextI18nLocales,
  allBcp47s,
  isSimplifiedZh,
  isTraditionalZh,
};
