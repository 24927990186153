import { styled } from '@mui/material/styles';
import { m as motion, Variants } from 'framer-motion';

import {
  BACKGROUND_COLOUR,
  DARK_COLOUR,
  EXIT_LASTING_DURATION,
  MAIN_COLOUR,
  SVG_CIRCLE_HIDE_DURATION,
  SWITCH_EXPAND_DURATION,
  TRACK_COLOUR_CHANGE_DURATION,
  TRACK_EXPAND_DURATION,
  TRACK_SHRINK_DURATION,
  TRACK_WIDTH,
  trackHeight,
} from './constants';
import { calculateTransitionVariants } from './utils';

export const trackVariants: Variants = {
  shrink: {
    width: trackHeight,
    transition: { ease: 'circOut', delay: 0, duration: TRACK_SHRINK_DURATION },
  },
  expand: {
    width: TRACK_WIDTH,
    backgroundColor: MAIN_COLOUR,
    transition: {
      delay: SVG_CIRCLE_HIDE_DURATION,
      duration: TRACK_EXPAND_DURATION,
    },
  },
  changeColorForward: {
    backgroundColor: DARK_COLOUR,
    transition: { delay: 0, duration: 0.5 },
  },
  changeColorBackward: {
    backgroundColor: BACKGROUND_COLOUR,
    transition: { delay: 0, duration: 0.5 },
  },
  expandAndChangeColor: {
    width: [null, TRACK_WIDTH, TRACK_WIDTH, TRACK_WIDTH, TRACK_WIDTH],
    backgroundColor: [null, MAIN_COLOUR, MAIN_COLOUR, DARK_COLOUR, DARK_COLOUR],
  },
};

const { duration, times } = calculateTransitionVariants(
  TRACK_EXPAND_DURATION,
  SWITCH_EXPAND_DURATION,
  TRACK_COLOUR_CHANGE_DURATION,
  EXIT_LASTING_DURATION
);

export const trackTransition = {
  ease: 'circOut',
  delay: SVG_CIRCLE_HIDE_DURATION,
  duration,
  times,
};

const StyledTrack = styled(motion.div)({
  transform: 'rotate(-45deg)',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  backgroundColor: BACKGROUND_COLOUR,
  borderRadius: '999px',
  height: trackHeight,
  width: TRACK_WIDTH,
});

export default StyledTrack;
