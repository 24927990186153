import { z } from 'zod';

export const paymentStatusResponseKey = [
  'paid',
  'pending',
  'refunded',
  'succeeded',
  'failed',
  'guaranteed',
  'charge',
  'no_charge',
  'requires_capture',
  'requires_action',
  'waiting_payment_result',
  'expired',
] as const;
export type PaymentStatusResponse = typeof paymentStatusResponseKey[number];

const transactionTypeKey = ['create', 'refund'] as const;
export type TransactionType = typeof transactionTypeKey[number];

const refundStatusKey = [
  'succeeded',
  'refunded',
  'waiting_refund_result',
] as const;
export type RefundStatus = typeof refundStatusKey[number];

const typeTicketTransaction = ['Deposit', 'Full payment'] as const;
export type TypeTicketTransactionMap = typeof typeTicketTransaction[number];

export const TRANSACTION_SOURCE = ['Merchant', 'System'] as const;
export type TransactionSource = typeof TRANSACTION_SOURCE[number];

export const ZTransactionWithoutRef = z.object({
  id: z.string().nullable(),
  transactionId: z.string().nullable(),
  createdTimestamp: z.string().nullish(),
  modifiedTimestamp: z.string().nullish(),
  pageCurrent: z.number().nullish(),
  reservationId: z.string(),
  outletId: z.string(),
  status: z.enum(paymentStatusResponseKey).nullish().catch(null),
  transactionGenerateId: z.string(),
  idempotentKey: z.string().nullable(),
  amount: z.string(),
  timeStatusLastest: z.string().nullish(),
  reason: z.string().nullish(),
  messageForCustomer: z.string().nullish(),
  refundReason: z.string().nullish(),
  refundMessage: z.string().nullish(),
  statusRefund: z.enum(refundStatusKey).nullish().catch(null),
  url: z.string().nullable(),
  type: z.enum(transactionTypeKey).nullable().catch(null),
  timeRefund: z.string().nullish(),
  requestPayload: z.object({
    metadata: z
      .object({
        type: z.enum(typeTicketTransaction).nullable().catch(null),
      })
      .nullish(),
  }),
  source: z.enum(TRANSACTION_SOURCE).nullable().catch(null),
});
