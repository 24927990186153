import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import React, { EffectCallback, ReactNode, useEffect, useRef } from 'react';
import { SessionData } from './types';
import useCallbackRef from '@/common/hooks/use-callback-ref';
import { SessionPostPayload } from '@@/pages/api/auth/session';
import Router from 'next/router';
import pDefer from 'p-defer';
import { axiClient } from '@/common/lib/axios-clean-trace';

export type SessionCtx = UseQueryResult<SessionData> & {
  sessionFetchedPromise: Promise<void>;
  clearSessionCache: () => Promise<void>;
};
export const SessionContext = React.createContext<SessionCtx | null>(null);

export const useSession = () => {
  const session = React.useContext(SessionContext);
  if (!session) throw Error('SessionProvider not found');
  return session;
};
export const useOnSessionUpdate = (
  effect: (data: SessionData) => ReturnType<EffectCallback>
) => {
  const { data, isFetched } = useSession();
  React.useEffect(() => {
    if (!isFetched || !data) return;
    return effect(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetched]);
};
export const useOnSessionError = (
  effect: (
    error: unknown,
    data: SessionData | undefined
  ) => ReturnType<EffectCallback> | any
) => {
  const { error, data } = useSession();
  React.useEffect(() => {
    if (error) return effect(error, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
};

export const SessionProvider = ({
  children,
  pagePropsSessionData,
}: {
  children: ReactNode;
  pagePropsSessionData?: SessionData;
}) => {
  const sessionFetchedDeferred = useRef(pDefer<void>());
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ['SESSION_PROVIDER_GET_SESSION'],
    queryFn: async () => {
      const seshData = SessionData.parse(
        (await axiClient.get('/api/auth/session')).data
      );
      return seshData;
    },
    onError: (err) => console.warn(err),
    refetchInterval: (_data, query) => {
      if (typeof window === 'undefined') return false;
      if (Router.pathname.startsWith('/login')) return false;
      if (query.state.error) return false;
      return 60 * 1000;
    },
  });

  const firstFetchDone = query.isFetched || query.isError;

  useEffect(() => {
    if (firstFetchDone) {
      sessionFetchedDeferred.current.resolve();
    }
  }, [firstFetchDone]);

  useEffect(() => {
    if (pagePropsSessionData) {
      queryClient.setQueryData(
        ['SESSION_PROVIDER_GET_SESSION'],
        pagePropsSessionData
      );
    }
  }, [pagePropsSessionData, queryClient]);

  const clearSessionCache = useCallbackRef(async () => {
    const payload: SessionPostPayload = { clearCache: true };
    await axiClient.post('/api/auth/session', payload);
  });

  const ctx: SessionCtx = React.useMemo(
    () => ({
      ...query,
      sessionFetchedPromise: sessionFetchedDeferred.current.promise,
      clearSessionCache,
    }),
    [query, clearSessionCache]
  );

  return (
    <SessionContext.Provider value={ctx}>{children}</SessionContext.Provider>
  );
};
