import { ZBrandWithoutRef } from '@/common/types/brand.base';
import { tryFn } from '@/common/utils';
import { z } from 'zod';
import { AES, enc } from 'crypto-js';
import { env } from '@/env.mjs';

// const jsonEncrypt = <T>(data: T) =>
//   AES.encrypt(
//     JSON.stringify(data),
//     String(env.HOSTV2_CRYPTOJS_SECRET)
//   ).toString();
const jsonDecrypt = (data: string) =>
  JSON.parse(
    AES.decrypt(data, String(env.HOSTV2_CRYPTOJS_SECRET)).toString(enc.Utf8)
  );

const Channel = z.object({
  status: z
    .enum(['not_applicable', 'not_setup', 'enabled', 'disabled'])
    .nullable()
    .catch(null),
});
const ChannelData = z.object({
  reserve: Channel.nullish(),
});
const reserveNotSetup = (brandOrOutlet: SwitchableOutlet | SwitchableBrand) => {
  const status = brandOrOutlet.channelDatas?.reserve?.status;
  return status === 'not_applicable' || status === 'not_setup';
};

export const SwitchableOutlet = z.object({
  id: z.string().min(1),
  omsOutletId: z.string().min(1),
  outletName: z.string(),
  outletLogo: z.string().nullish(),
  brandId: z.string().nullish(),
  channelDatas: ChannelData.nullish(),
});
export type SwitchableOutlet = z.infer<typeof SwitchableOutlet>;

export const SwitchableBrand = z.object({
  id: z.string().min(1),
  omsBrandId: z.string().min(1),
  brandName: z.string(),
  brandLogo: z.string().nullish(),
  routeToHostv3: z.boolean().nullish(),
  confirmationNotificationEnabled: z.boolean().nullish(),
  isEnableMagicBell: z.boolean().nullish(),
  offerHostv3: z.boolean().nullish(),
  outlets: z.array(SwitchableOutlet),
  channelDatas: ChannelData.nullish(),
  isEnableTableCombination: z.boolean().nullish(),
  isEnablePaymentRequest: z.boolean().nullish(),
});
export type SwitchableBrand = z.infer<typeof SwitchableBrand>;

export const ZOutletLoginInfo = z
  .object({
    token: z.string(),
    info: SwitchableOutlet,
    brandCountryCode: z.string().nullish(),
    brandTimezone: z.string().nullish(),
    brandApiVersion: z.string().nullish(), // TODO: DEPRECATED
    brandRouteToHostv3: z.boolean().nullish(),
    brandOfferHostv3: z.boolean().nullish(),
    quickCodeId: z.number().nullish(),
  })
  .transform(
    ({ info, token, brandApiVersion, brandRouteToHostv3, ...rest }) => ({
      ...rest,
      hostV2AuthToken: token,
      outlet: info,
      brandRouteToHostv3: tryFn((): boolean => {
        if (brandRouteToHostv3 !== undefined) return !!brandRouteToHostv3;
        if (brandApiVersion === '3') return true;
        const { data } = JSON.parse(
          Buffer.from(String(token.split('.')[1]), 'base64').toString()
        ) as any;
        const { apiVersion } = jsonDecrypt(data) as any;
        return apiVersion === '3';
      }),
    })
  );
export type OutletLoginInfo = z.infer<typeof ZOutletLoginInfo>;

export const ZEmailLoginInfo = z.object({
  email: z.string().nullish(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
  phoneNumber: z.string().nullish(),
  userRefId: z.string().nullish(),
  brands: z.array(SwitchableBrand).transform((brands) =>
    brands
      .filter((brand) => !reserveNotSetup(brand))
      .map((brand) => ({
        ...brand,
        outlets: brand.outlets.filter((outlet) => !reserveNotSetup(outlet)),
      }))
  ),
  accountId: z.string(),
  accountName: z.string().nullish(),
  accountCountry: z.string().nullish(),
  accountGmtOffset: z.number().nullish(),
  mpUserToken: z.string(),
  isImpersonation: z.boolean().nullish(),
});
export type EmailLoginInfo = z.infer<typeof ZEmailLoginInfo>;

export const SessionData = z.object({
  isOutletLoggedIn: z.boolean(),
  isUserLoggedIn: z.boolean(),
  outlet: SwitchableOutlet.optional(),
  hostV2AuthToken: z.string().optional(),
  quickCodeId: z.number().nullish(),
  userInfo: ZEmailLoginInfo.partial().omit({ mpUserToken: true }).optional(),
  /**
   * exists when `sessionData.outlet` exists
   */
  brand: SwitchableBrand.extend({
    countryCode: ZBrandWithoutRef.shape.countryCode,
    timezone: ZBrandWithoutRef.shape.timezone,
    outlets: z.array(SwitchableOutlet.pick({ id: true })),
  }).optional(),
  omsAccount: z
    .object({
      id: z.string().min(1),
      name: z.string().min(1),
      country: z.string().nullish(),
      email: z.string().nullish(),
      phone: z.string().nullish(),
      gmtOffset: z.number().nullish(),
    })
    .nullish(),
});
export type SessionData = z.infer<typeof SessionData>;

export const ZAuthEmailPayload = z.object({
  email: z.string(),
  sha512Pw: z.string(),
});
export type AuthEmailPayload = z.infer<typeof ZAuthEmailPayload>;

export const ZForgetPasswordPayload = z.object({
  userEmail: z.string(),
});
export type ForgetPasswordPayload = z.infer<typeof ZForgetPasswordPayload>;

export const ZAuthOutletPayload = z.object({
  code: z.string(),
  sha512Pin: z.string(),
});
export type AuthOutletPayload = z.infer<typeof ZAuthOutletPayload>;
export const ZSwitchOutletPayload = z.object({
  mpUserToken: z.string(),
  omsOutletId: z.string(),
});
export type SwitchOutletPayload = z.infer<typeof ZSwitchOutletPayload>;
