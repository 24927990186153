import styled from '@emotion/styled';
import { m as motion, Variants } from 'framer-motion';

import { progressBarDimensions, TRACK_SHRINK_DURATION } from './constants';

export const progressBarAnchorVariants: Variants = {
  show: {
    opacity: 1,
    transition: {
      delay: TRACK_SHRINK_DURATION,
      duration: 0.5,
      type: 'spring',
    },
  },
  hide: { opacity: 0, transition: { duration: 0.25 } },
};

const ProgressBarAnchor = styled(motion.div)({
  position: 'absolute',
  height: progressBarDimensions,
  width: progressBarDimensions,
});

export default ProgressBarAnchor;
