// @ts-check
/* eslint-disable no-process-env */

import { z } from 'zod';

/**
 * Specify your server-side environment variables schema here. This way you can ensure the app isn't
 * built with invalid env vars.
 */
const server = z.object({
  NODE_ENV: z.enum(['development', 'test', 'production']),
  DEBUG_I18NEXT: z.enum(['false', 'true']).optional(),
  ANALYZE: z.enum(['false', 'true']).optional(),
  SKIP_ENV_VALIDATION: z.enum(['false', 'true']).optional(),
  NEXT_RUNTIME: z.enum(['edge', 'nodejs']).optional(),
  MERCHANT_PASS_API: z.string().url(),
  MERCHANT_PASS_CLIENT_ID: z.string(),
  MERCHANT_PASS_CLIENT_SECRET: z.string(),
  SESSION_COOKIE_PASSWORD: z.string(),
  OUTLET_BRAND_PASSWORD: z.string(),
  HOSTV2_CRYPTOJS_SECRET: z.string(),
  OMS_KEY: z.string().min(1),
  NEWRELIC_LICENSE_KEY: z.string(),
  UPSTASH_REDIS_URL: z.string().url(),
  UPSTASH_REDIS_TOKEN: z.string().min(1),
});

/**
 * Specify your client-side environment variables schema here. This way you can ensure the app isn't
 * built with invalid env vars. To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
const client = z.object({
  NEXT_PUBLIC_DINEIN_BACKEND_API: z.string().url(),
  NEXT_PUBLIC_OMS_API: z.string().url(),
  NEXT_PUBLIC_ANALYTICS_TRACKING_SOURCE_ID: z.string(),
  NEXT_PUBLIC_HOST_CLASSIC_URL: z.string().url(),
  NEXT_PUBLIC_ENABLE_CIRCULAR_DEPENDENCY_PLUGIN: z
    .enum(['false', 'true'])
    .optional(),
  NEXT_PUBLIC_DISABLE_V2_SESSION_MIGRATE_GUARD: z
    .enum(['false', 'true'])
    .optional(),
  NEXT_PUBLIC_CANDU_CLIENT_TOKEN: z.string(),
  NEXT_PUBLIC_VERCEL_ENV: z
    .enum(['development', 'preview', 'production'])
    .optional(),
  NEXT_PUBLIC_MQTT_URL: z.string().url(),
  NEXT_PUBLIC_MQTT_ENV: z.enum(['staging', 'production-table-management']),
  NEXT_PUBLIC_VERCEL_URL: z.string().optional(),
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF: z.string().optional(),
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA: z.string().optional(),
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE: z.string().optional(),
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_LOGIN: z.string().optional(),
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_NAME: z.string().optional(),
  NEXT_PUBLIC_VERCEL_GIT_PULL_REQUEST_ID: z.string().optional(),
  NEXT_PUBLIC_MERCHANT_PASS_PUBLISHABLE_KEY: z.string(),
  NEXT_PUBLIC_MAGIC_BELL_API_KEY: z.string(),
  NEXT_PUBLIC_ENTITLEMENT_SCRIPT: z.string(),
  NEXT_PUBLIC_ENTITLEMENT_TOKEN: z.string(),
  NEXT_PUBLIC_LINK_CUSTOMER_DETAILS: z.string().optional(),
});

/**
 * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
 * middlewares) or client-side so we need to destruct manually.
 *
 * @type {Record<keyof z.infer<typeof server> | keyof z.infer<typeof client>, string | undefined>}
 */
const processEnv = {
  NODE_ENV: process.env.NODE_ENV,
  DEBUG_I18NEXT: process.env.DEBUG_I18NEXT,
  ANALYZE: process.env.ANALYZE,
  SKIP_ENV_VALIDATION: process.env.SKIP_ENV_VALIDATION,
  NEXT_RUNTIME: process.env.NEXT_RUNTIME,
  MERCHANT_PASS_API: process.env.MERCHANT_PASS_API,
  MERCHANT_PASS_CLIENT_ID: process.env.MERCHANT_PASS_CLIENT_ID,
  MERCHANT_PASS_CLIENT_SECRET: process.env.MERCHANT_PASS_CLIENT_SECRET,
  SESSION_COOKIE_PASSWORD: process.env.SESSION_COOKIE_PASSWORD,
  OUTLET_BRAND_PASSWORD: process.env.OUTLET_BRAND_PASSWORD,
  HOSTV2_CRYPTOJS_SECRET: process.env.HOSTV2_CRYPTOJS_SECRET,
  OMS_KEY: process.env.OMS_KEY,
  UPSTASH_REDIS_URL: process.env.UPSTASH_REDIS_URL,
  UPSTASH_REDIS_TOKEN: process.env.UPSTASH_REDIS_TOKEN,

  NEXT_PUBLIC_DINEIN_BACKEND_API: process.env.NEXT_PUBLIC_DINEIN_BACKEND_API,
  NEXT_PUBLIC_OMS_API: process.env.NEXT_PUBLIC_OMS_API,
  NEXT_PUBLIC_ANALYTICS_TRACKING_SOURCE_ID:
    process.env.NEXT_PUBLIC_ANALYTICS_TRACKING_SOURCE_ID,
  NEXT_PUBLIC_HOST_CLASSIC_URL: process.env.NEXT_PUBLIC_HOST_CLASSIC_URL,
  NEXT_PUBLIC_ENABLE_CIRCULAR_DEPENDENCY_PLUGIN:
    process.env.NEXT_PUBLIC_ENABLE_CIRCULAR_DEPENDENCY_PLUGIN,
  NEXT_PUBLIC_DISABLE_V2_SESSION_MIGRATE_GUARD:
    process.env.NEXT_PUBLIC_DISABLE_V2_SESSION_MIGRATE_GUARD,
  NEXT_PUBLIC_CANDU_CLIENT_TOKEN: process.env.NEXT_PUBLIC_CANDU_CLIENT_TOKEN,
  NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
  NEXT_PUBLIC_MQTT_URL: process.env.NEXT_PUBLIC_MQTT_URL,
  NEXT_PUBLIC_MQTT_ENV: process.env.NEXT_PUBLIC_MQTT_ENV,
  NEXT_PUBLIC_VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_LOGIN:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_LOGIN,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_NAME:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_NAME,
  NEXT_PUBLIC_VERCEL_GIT_PULL_REQUEST_ID:
    process.env.NEXT_PUBLIC_VERCEL_GIT_PULL_REQUEST_ID,
  NEXT_PUBLIC_MERCHANT_PASS_PUBLISHABLE_KEY:
    process.env.NEXT_PUBLIC_MERCHANT_PASS_PUBLISHABLE_KEY,
  NEWRELIC_LICENSE_KEY: process.env.NEWRELIC_LICENSE_KEY,
  NEXT_PUBLIC_MAGIC_BELL_API_KEY: process.env.NEXT_PUBLIC_MAGIC_BELL_API_KEY,
  NEXT_PUBLIC_ENTITLEMENT_SCRIPT: process.env.NEXT_PUBLIC_ENTITLEMENT_SCRIPT,
  NEXT_PUBLIC_ENTITLEMENT_TOKEN: process.env.NEXT_PUBLIC_ENTITLEMENT_SCRIPT,
  NEXT_PUBLIC_LINK_CUSTOMER_DETAILS:
    process.env.NEXT_PUBLIC_LINK_CUSTOMER_DETAILS,
};

// Don't touch the part below
// --------------------------

const isProduction = () => process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';

const merged = server.merge(client);

/** @typedef {z.input<typeof merged>} MergedInput */
/** @typedef {z.infer<typeof merged>} MergedOutput */
/** @typedef {z.SafeParseReturnType<MergedInput, MergedOutput>} MergedSafeParseReturn */

let env = /** @type {MergedOutput} */ (processEnv);

/**
 * - Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation.
 *   This is especially useful for Docker builds.
 * - Private envs are not available on Vercel Edge runtime for some reason (middleware.ts)
 */
const skip = env.SKIP_ENV_VALIDATION === 'true' || env.NEXT_RUNTIME === 'edge';
if (!skip) {
  const isServer = typeof window === 'undefined';

  const parsed = /** @type {MergedSafeParseReturn} */ (
    isServer
      ? merged.safeParse(processEnv) // on server we can validate all env vars
      : client.safeParse(processEnv) // on client we can only validate the ones that are exposed
  );

  if (parsed.success === false) {
    console.error(
      '❌ Invalid environment variables:',
      parsed.error.flatten().fieldErrors
    );
    throw new Error('Invalid environment variables');
  }

  env = new Proxy(parsed.data, {
    get(target, prop) {
      if (typeof prop !== 'string') return undefined;
      // Throw a descriptive error if a server-side env var is accessed on the client
      // Otherwise it would just be returning `undefined` and be annoying to debug
      if (!isProduction() && !isServer && !prop.startsWith('NEXT_PUBLIC_'))
        throw new Error(
          process.env.NODE_ENV === 'production'
            ? '❌ Attempted to access a server-side environment variable on the client'
            : `❌ Attempted to access server-side environment variable '${prop}' on the client`
        );
      return target[/** @type {keyof typeof target} */ (prop)];
    },
  });
}

export { env };
