import { intersection } from 'lodash-es';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { z } from 'zod';
import {
  locales,
  defaultLocale,
  ZLocale,
  ZBcp47,
  subPathToBcp47,
  bcp47ToSubPath,
} from '../locales.cjs';
import { Extend } from '../utils';
import { usePreferredLocale } from './storage';
import useCallbackRef from './use-callback-ref';
import useOutletSwitch from './use-outlet-switch';
import { Locale as DateFnsLocale } from 'date-fns';
import { enUS, zhCN, zhHK, zhTW, ms } from 'date-fns/locale';

export type Bcp47 = z.infer<typeof ZBcp47>;
export const ZLang = z.object({
  id: z.enum(['ENGLISH', 'SIMPLIFIED_CHINESE', 'TRADITIONAL_CHINESE', 'MALAY']),
  bcp47s: z.array(ZBcp47),
  label: z.string(),
  disabled: z.boolean().optional(),
});
export type Lang = z.infer<typeof ZLang>;
export const allLangs: Lang[] = [
  {
    id: 'ENGLISH',
    bcp47s: ['en-SG', 'en-MY', 'en-HK', 'en-TW'],
    label: 'English',
  },
  {
    id: 'SIMPLIFIED_CHINESE',
    bcp47s: ['zh-SG', 'zh-MY'],
    label: '简体中文',
  },
  {
    id: 'TRADITIONAL_CHINESE',
    bcp47s: ['zh-Hant-HK', 'zh-Hant-TW'],
    label: '繁體中文',
  },
  {
    id: 'MALAY',
    bcp47s: ['ms-MY'],
    label: 'Bahasa Melayu',
  },
];

const defaultDateLocale = enUS;

const dateLocales: Record<Bcp47, DateFnsLocale> = {
  'en-SG': enUS,
  'en-MY': enUS,
  'en-HK': enUS,
  'en-TW': enUS,
  'zh-SG': zhCN,
  'zh-MY': zhCN,
  'zh-Hant-HK': zhHK,
  'zh-Hant-TW': zhTW,
  'ms-MY': ms,
};

export type DUReplacer = (args: {
  match: string;
  digit: string;
}) => readonly [singular: string, plural: string];
export type DurationUnits = {
  years: DUReplacer;
  months: DUReplacer;
  weeks: DUReplacer;
  days: DUReplacer;
  hours: DUReplacer;
  minutes: DUReplacer;
  seconds: DUReplacer;
};

export type LocaleCfg = Extend<
  z.infer<typeof ZLocale>,
  {
    'BCP 47': Bcp47 | undefined;
    changeLang: (bcp47: string | null | undefined) => Promise<void>;
    languages: Lang[];
    selectedLang: Lang;
    preferredLocale: string;
    dateLocale: Locale;
    enableCardGuarantee: boolean;
  }
>;
export default function useLocaleCfg(): LocaleCfg {
  const [preferredLocale, setPreferredLocale] = usePreferredLocale();
  const { countryCode } = useOutletSwitch();
  const router = useRouter();
  const { locale: langSubPath, pathname, asPath, query } = router;
  const bcp47 = useMemo(() => subPathToBcp47(langSubPath), [langSubPath]);
  const locale = useMemo(
    () =>
      locales.find((loc) => loc['ISO 3166-2'] === countryCode) || defaultLocale,
    [countryCode]
  );
  const changeLang: LocaleCfg['changeLang'] = useCallbackRef(
    async (newLang) => {
      if (!newLang) return;
      const nextLocale = bcp47ToSubPath(newLang, 'BCP47_TO_PATH');
      if (!nextLocale) return setPreferredLocale('');
      setPreferredLocale(newLang);
    }
  );
  const languages = useMemo(
    () =>
      allLangs.filter(
        (lng) => intersection(locale.bcp47s, lng.bcp47s).length > 0
      ),
    [locale.bcp47s]
  );
  const selectedLang = useMemo(
    () =>
      allLangs.find((it) => it.bcp47s.includes(bcp47 || '')) || allLangs[0]!,
    [bcp47]
  );

  const enableCardGuarantee = locale['ISO 3166-2'] !== 'TW';

  const output = useMemo(
    () => ({
      ...locale,
      'BCP 47': bcp47,
      changeLang,
      languages,
      selectedLang,
      preferredLocale,
      dateLocale: bcp47 ? dateLocales[bcp47] : defaultDateLocale,
      enableCardGuarantee,
    }),
    [
      bcp47,
      changeLang,
      languages,
      locale,
      preferredLocale,
      selectedLang,
      enableCardGuarantee,
    ]
  );

  return output;
}

export const AutoSwitchLocale = () => {
  const { changeLang, preferredLocale } = useLocaleCfg();
  const router = useRouter();
  const { locale, pathname } = router;

  const isPathIgnored = useCallbackRef(() => {
    const ignoreConditions: (() => boolean)[] = [
      () => pathname.startsWith('/__test'),
    ];
    return ignoreConditions.some((condition) => condition());
  });

  useEffect(() => {
    if (isPathIgnored()) return;
    if (locale === 'DEFAULT_LOCALE' && preferredLocale) {
      changeLang(preferredLocale);
    }
  }, [locale, preferredLocale, changeLang, isPathIgnored]);

  useEffect(() => {
    if (!preferredLocale) return;
    // If the router's locale is different from the preferred locale, redirect to the preferred locale
    const nextLocale = bcp47ToSubPath(preferredLocale, 'BCP47_TO_PATH');
    if (router.locale !== nextLocale) {
      router.replace(
        { pathname: router.pathname, query: router.query },
        router.asPath,
        {
          locale: nextLocale,
        }
      );
    }
  }, [preferredLocale, router]);

  return null;
};
