import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { eachDayOfInterval, startOfWeek, endOfWeek } from 'date-fns';

export default class DateAdapter extends AdapterDateFns {
  constructor(props: any) {
    super(props);
  }

  public getWeekdays = () => {
    const now = new Date();
    return eachDayOfInterval({
      start: startOfWeek(now, { locale: this.locale }),
      end: endOfWeek(now, { locale: this.locale }),
    }).map((day) => this.formatByString(day, 'EEE'));
  };
}
