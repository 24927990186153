import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';

const isPreventNavAnimationAtom = atom(false);
const delay = 300;
const iOS =
  typeof navigator !== 'undefined' &&
  /iPad|iPhone|iPod/.test(navigator.userAgent);

export const useDraggingListener = () => {
  const setIsPreventNavAnimation = useSetAtom(isPreventNavAnimationAtom);
  useEffect(() => {
    if (!iOS) return;

    let timeout: ReturnType<typeof setTimeout>;
    const handler = () => {
      setIsPreventNavAnimation(true);
      clearTimeout(timeout);
      timeout = setTimeout(() => setIsPreventNavAnimation(false), delay);
    };
    window.addEventListener('touchmove', handler);
    return () => {
      window.removeEventListener('touchmove', handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useIsPreventNavAnimation = () =>
  useAtomValue(isPreventNavAnimationAtom);
