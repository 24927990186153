import { z } from 'zod';

export const ZAreaBlockPayload = z.object({
  blockIndefinitely: z.boolean().optional(),
  blockTemporarily: z.boolean().optional(),
  date: z.string().optional(),
  blocks: z
    .array(
      z.object({
        date: z.string(),
        from: z.string(),
        to: z.string(),
      })
    )
    .optional(),
});

export type AreaBlockPayload = z.infer<typeof ZAreaBlockPayload>;

export const ZAreaBlock = z.object({
  id: z.string(),
  date: z.string(),
  entireDay: z.number().nullable(),
  outletId: z.string(),
  areaId: z.number(),
  from: z.string(),
  to: z.string(),
});

export type AreaBlock = z.infer<typeof ZAreaBlock>;
