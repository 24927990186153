import { isProduction, isStaging } from '@/common/utils';
import { useEffect } from 'react';
import pkg from '@@/package.json'
import { env } from '@/env.mjs';

export default function usePrintDebugInfo() {
  useEffect(() => {
    if (isStaging()) {
      console.log('NEXT_PUBLIC_VERCEL_URL', env.NEXT_PUBLIC_VERCEL_URL);
      console.log(
        'NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF',
        env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF
      );
      console.log(
        'NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA',
        env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
      );
    }
    if (isProduction()) {
      console.log('version', pkg.version);
    }
  }, []);
}
