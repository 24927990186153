import { z } from 'zod';
import { add, isFuture, isToday, isValid, parse } from 'date-fns';
import {
  ALLOWABLE_ACTIONS_BY_TIME,
  ApiRsrvStatus,
  ApiTableStatus,
  API_RSRV_STATUS_MAP,
  API_TABLE_STATUS_MAP,
  EDITABLE_BY_TIME,
  ReservationStatus,
  RESRV_SRC,
  TimeState,
} from './reservation-status-flow';
import { ZCustomerWithoutRef } from './customer.base';
import { ZTicketWithoutRef } from './ticket.base';
import { ZTableWithoutRef } from './table.base';
import {
  ZReservationPaymentRequest,
  ZServiceTimingWithoutRef,
} from './schedule.base';
import { ZTransactionWithoutRef } from './transaction.base';
import { ZAreaWithoutRef } from './area.base';
import { ZTableInAreaWithoutRef } from './table-in-area.base';
import { findKey } from 'lodash-es';
import { ZConfirmationNotification } from './confirmation-notification';

export const ZReservation = z.object({
  id: z.string(),
  code: z.string(),
  language: z.string().nullable(),
  source: z.enum(RESRV_SRC).nullable().catch(null),
  diningInterval: z.number().nullable(),
  outletId: z.string(),
  serviceTimingId: z.string().nullish(),
  customerId: z.string().nullable(),
  customerPhone: z.string().nullable(),
  customerFirstName: z.string().nullish(),
  customerLastName: z.string().nullish(),
  reservationDate: z
    .string()
    .refine((val) => isValid(parse(val, 'yyyy-MM-dd', new Date())), {
      message: 'invalid "reservationDate"',
    }),
  reservationTime: z
    .string()
    .refine((val) => isValid(parse(val, 'HH:mm:ss', new Date())), {
      message: 'invalid "reservationTime"',
    }),
  numberOfAdults: z.number(),
  numberOfChildren: z.number(),
  extraAdult: z.number().nullable(),
  ticket: ZTicketWithoutRef.partial().nullish(),
  extraChildren: z.number().nullable(),
  status: z.number(),
  isWalkIn: z.boolean(),
  notes: z.string().nullable(),
  notesDiner: z.string().nullable(),
  staffNotes: z.string().nullable(),
  occasions: z.string().nullable(),
  otherOccasions: z.string().optional().nullable(),
  createdByRestaurant: z.boolean().nullable(),
  modifiedByRestaurant: z.boolean().nullable(),
  cancelledByRestaurant: z.boolean().nullable(),
  allowSmsNotify: z.boolean().nullable(),
  allowEmailNotify: z.boolean().nullable(),
  allowAutomatedCallNotify: z.boolean().nullable().optional(),
  allowConfirmationNotify: z.boolean().nullable(),
  allowReminderNotify: z.boolean().nullable(),
  timeSendReminder: z.string().nullable(),
  confirmedAt: z.string().nullable(),
  cancelledAt: z.string().nullable(),
  seatedAt: z.string().nullable(),
  departedAt: z.string().nullable(),
  createdTimestamp: z.string(),
  modifiedTimestamp: z.string().nullable(),
  tables: z
    .array(
      ZTableWithoutRef.extend({
        tableInAreas: z.array(ZTableInAreaWithoutRef).optional(),
      })
    )
    .optional()
    .nullable(),
  serviceTiming: ZServiceTimingWithoutRef.partial()
    .extend({
      ticket: ZTicketWithoutRef.partial().nullish(),
      areas: z.array(ZAreaWithoutRef.partial()).nullish(),
    })
    .nullable()
    .optional(),
  user: ZCustomerWithoutRef.nullish(),
  pax: z.number().nullish(),
  createdByCustomer: z.boolean().nullish(),
  transaction: ZTransactionWithoutRef.nullish(),
  transactions: z.array(ZTransactionWithoutRef).nullish(),
  confirmationNotifications: z.array(ZConfirmationNotification).optional(),
  confirmByRestaurant: z.boolean().nullable().optional(),
  reservationPaymentRequest: ZReservationPaymentRequest.nullish().optional(),
  tags: z
    .array(
      z.object({
        id: z.string(),
        title: z.string(),
      })
    )
    .nullable()
    .optional(),
});
export type Reservation = z.infer<typeof ZReservation>;

export const ZReservationsInTable = ZTableInAreaWithoutRef.extend({
  table: ZTableWithoutRef.nullish(),
  reservations: z.array(ZReservation),
});
export type ReservationsInTable = z.infer<typeof ZReservationsInTable>;

export const enhanceReservation = <T extends Reservation>(it: T) => {
  const rDateTime = parse(
    `${it.reservationDate} ${it.reservationTime}`,
    'yyyy-MM-dd HH:mm:ss',
    new Date()
  );
  const rLimitTime = add(rDateTime, {
    seconds: it.diningInterval || it.serviceTiming?.diningInterval,
  });
  const rTimeState: TimeState = isToday(rDateTime)
    ? 'TODAY'
    : isFuture(rDateTime)
    ? 'FUTURE'
    : 'PAST';
  const apiRsrvStatus = findKey(API_RSRV_STATUS_MAP, (v) => v === it.status) as
    | ApiRsrvStatus
    | undefined;
  if (!apiRsrvStatus) throw Error(`invalid status: ${it.status}`);

  const apiTableStatus = findKey(
    API_TABLE_STATUS_MAP,
    (v) => v === it.tables?.[0]?.status
  ) as ApiTableStatus | undefined;

  type Falsy = false | 0 | '' | null | undefined;
  const conditions: (ReservationStatus | Falsy)[] = [
    apiRsrvStatus === 'PENDING' && 'R::PENDING',
    apiRsrvStatus === 'EXPIRED' && 'R::EXPIRED',
    apiRsrvStatus === 'SEATED' &&
      apiTableStatus === 'BILL_REQUESTED' &&
      'R::BILL',
    apiRsrvStatus === 'SEATED' &&
      apiTableStatus === 'OVERSTAY' &&
      'R::OVERSTAYED',
    apiRsrvStatus === 'BOOKED' && 'R::BOOKED',
    apiRsrvStatus === 'CONFIRMED' && 'R::CONFIRMED',
    apiRsrvStatus === 'SEATED' && 'R::SEATED',
    apiRsrvStatus === 'COMPLETED' && 'R::COMPLETED',
    apiRsrvStatus === 'LATE' && 'R::LATE',
    apiRsrvStatus === 'NO_SHOW' && 'R::NO_SHOW',
    apiRsrvStatus === 'CANCELLED' && 'R::CANCELLED',
  ];
  const rStatus = conditions.find(Boolean);
  if (!rStatus) throw Error('cannot map reservation status');

  const allowableActions = ALLOWABLE_ACTIONS_BY_TIME[rTimeState][rStatus];
  const isEditable = EDITABLE_BY_TIME[rTimeState].includes(rStatus);

  return {
    ...it,
    statusStr: apiRsrvStatus,
    tableStatusStr: apiTableStatus,
    rStatus,
    allowableActions,
    isEditable,
    rDateTime,
    rLimitTime,
    rTimeState,
  };
};
export const ZReservationItem = ZReservation.transform(enhanceReservation);
export type ReservationItem = z.infer<typeof ZReservationItem>;

export const AffectedReason = {
  SERVICE_TIMING_DELETED: 'Service timing being deleted.',
  TICKET: 'Ticket being replaced',
  AREA: 'Table assigned being in an area that is no longer part of the service.',
  INTERVAL_TIME: 'Timeslot Interval has been changed for the service',
  TIME: 'Service time being outside the range of new bookable times',
  MIN_PARTY_SIZE:
    'Minimum Party Size being for a party size less than the new maximum value.',
  MAX_PARTY_SIZE:
    'Maximum Party Size being for a party size more than the new maximum value.',
  MAXIMUM_RESERVATIONS:
    'Maximum Reservations being overbooked for more than the new maximum value.',
  MAXIMUM_GUESTS:
    'Maximum Guests being under booked for less than the new maximum value.',
  CREATE_SPECIFIC_SERVICE:
    'Special Schedule being overridden by the special schedule day',
  DATE: 'Service day being outside the range of days and services',
  BLOCK_OUT_DATE_UPDATE: 'Block out date has been changed',
  BLOCK_OUT_DATE_CREATE: 'Blockout day being created on that service',
  CLASSED_RESERVATION: 'Reservation has been hold',
  REMOVE_TABLE: 'Reservations affected due to tables being deleted.',
  PAX_TABLE: 'Reservations affected due to change in tables’ pax settings.',
  TABLE_AREA:
    'Reservations affected due to table’s area not available for service.',
  TABLE_DISABLE: 'Reservations affected due to tables being disabled.',
} as const;
export const ZAffectedReason = z.nativeEnum(AffectedReason);
export type AffectedReason = z.infer<typeof ZAffectedReason>;
export const ZAffectedReservation = ZReservation.extend({
  reasons: z.array(ZAffectedReason).min(1),
  _resolved: ZReservationItem.optional(),
}).transform(enhanceReservation);
export type AffectedReservation = z.infer<typeof ZAffectedReservation>;

export class BlockingAffectedResError extends Error {
  name = 'BlockingAffectedResError';
  blockingAffectedRes: AffectedReservation[];

  constructor(blockingAffectedRes: AffectedReservation[]) {
    super(`Blocking affected reservations: ${blockingAffectedRes.length}`);
    this.blockingAffectedRes = z
      .array(ZAffectedReservation)
      .min(1)
      .parse(blockingAffectedRes);
  }
}

export class SeatingAbortError extends Error {
  name = 'SeatingAbortError';
  constructor(message = 'Seating: User aborted') {
    super(message);
  }
}

export class SeatingError extends Error {
  name = 'SeatingError';
  isOutsideOfSeatWindow = false;
  constructor({
    message = 'Seating Error',
    isOutsideOfSeatWindow,
  }: {
    message?: string;
    isOutsideOfSeatWindow?: boolean;
  }) {
    super(message);
    this.isOutsideOfSeatWindow = !!isOutsideOfSeatWindow;
  }
}

export const affectedResByReason = (
  affectedReservations: AffectedReservation[],
  reasons: AffectedReason[]
) => {
  return affectedReservations.filter((reservation) => {
    const reason = reservation.reasons[0];
    if (!reason) return false;
    return reasons.includes(reason);
  });
};
