import React from 'react';
import useCallbackRef from './use-callback-ref';

const useFnOnce = (fn: () => void) => {
  const ref = React.useRef(false);
  return useCallbackRef(() => {
    if (!ref.current) {
      ref.current = true;
      fn();
    }
  });
};

export default useFnOnce;
