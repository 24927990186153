import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { atom, useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';

const isLaunchedAsPwaAtom = atom(false);

export const usePwa = () => {
  const [isLaunchedAsPwa] = useAtom(isLaunchedAsPwaAtom);

  return { isLaunchedAsPwa };
};

export const PwaDetect = () => {
  const setIsLaunchedAsPwa = useSetAtom(isLaunchedAsPwaAtom);
  const matches = useMediaQuery('(display-mode: standalone)');

  useEffect(() => {
    setIsLaunchedAsPwa(matches);
  }, [matches, setIsLaunchedAsPwa]);

  return null;
};

export const usePwaOrMobileView = () => {
  const { breakpoints } = useTheme();
  const isMobileScreen = useMediaQuery(breakpoints.down('md'));

  return {
    isMobileView: isMobileScreen,
  };
};
