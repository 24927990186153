import { z } from 'zod';
import { ZAreaBlock } from './area-block';

export const ZAreaWithoutRef = z.object({
  id: z.string().optional(),
  areaId: z.string().optional(),
  outletId: z.string().optional(),
  layoutId: z.string().nullable().optional(),
  name: z.string(),
  displayName: z.string().nullable().optional(),
  order: z.number().nullable(),
  color: z.string().catch(''),
  bookableOnline: z.boolean().nullable().optional(),
  combineAllTable: z.boolean().nullable().optional(),
  maxPax: z.number().nullish(),
  minPax: z.number().nullish(),
  createdTimestamp: z.string().optional(),
  modifiedTimestamp: z.string().nullable().optional(),
  deletedTimestamp: z.string().nullable().optional(),
  areaBlocks: z.array(ZAreaBlock).optional(),
  enabled: z.number().optional(),
  x: z.number().nullable().optional(),
  y: z.number().nullable().optional(),
});
