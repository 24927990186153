import { z } from 'zod';

export enum ConfirmationNotificationChannelType {
  Phone = 'phone',
  Email = 'email',
  SMS = 'sms',
}

export enum ReservationNotificationType {
  ConfirmSMSBefore24h = 'confirm_sms_before_24h',
  ConfirmSMSBefore5h = 'confirm_sms_before_5h',
  ConfirmSMSNow = 'confirm_sms_now',
  ConfirmSMSBeforeRefund24h = 'confirm_sms_before_refund_24h',

  ConfirmEmailBefore24h = 'confirm_email_before_24h',
  ConfirmEmailBefore5h = 'confirm_email_before_5h',
  ConfirmEmailNow = 'confirm_email_now',
  ConfirmEmailBeforeRefund24h = 'confirm_email_before_refund_24h',

  ConfirmCallBefore3h = 'confirm_call_before_3h',
  ConfirmCallBefore2h = 'confirm_call_before_2h',
  ConfirmCallBefore1h = 'confirm_call_before_1h',
  ConfirmCallOnDemand = 'confirm_call_on_demand',

  ReminderSMSBefore24h = 'reminder_sms_before_24h',
  ReminderEmailBefore24h = 'reminder_email_before_24h',
}

export enum ReservationNotificationResult {
  Confirmed = 'confirmed',
  Cancelled = 'cancelled',
  Missed = 'missed',
}

export const ZConfirmationNotification = z.object({
  id: z.string(),
  reservationId: z.string(),
  scheduleId: z.string().optional().nullable(),
  channel: z.nativeEnum(ConfirmationNotificationChannelType),
  type: z.nativeEnum(ReservationNotificationType),
  isSent: z.boolean(),
  sendAt: z.number(),
  result: z.nativeEnum(ReservationNotificationResult).nullable(),
});

export type ConfirmationNotification = z.infer<
  typeof ZConfirmationNotification
>;
