import { z } from 'zod';

export const ZBrandWithoutRef = z.object({
  id: z.string(),
  brandCoverImage: z.string().nullish(),
  brandLogo: z.string().nullish(),
  brandName: z.string().nullish(),
  brandReceiptLogo: z.string().nullish(),
  brandShortName: z.string(),
  countryCode: z.string(),
  countryName: z.string().nullish(),
  createdTimestamp: z.string().nullish(),
  deletedTimestamp: z.string().nullish(),
  enableGoogleBooking: z.boolean().nullish(),
  enabled: z.boolean().nullish(),
  modifiedTimestamp: z.string().nullish(),
  oddleEntityId: z.string().nullish(),
  omsBrandId: z.string().nullish(),
  timezone: z.string(),
  offerHostv3: z.boolean().nullish(),
  routeToHostv3: z.boolean().nullish(),
  isEnableMagicBell: z.boolean().nullish(),
  isEnableTableCombination: z.boolean().nullish(),
  isEnablePaymentRequest: z.boolean().nullish(),
});
