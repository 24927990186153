import { styled } from '@mui/material/styles';
import { m as motion, Variants } from 'framer-motion';

import {
  SVG_CIRCLE_HIDE_DURATION,
  SWITCH_EXPAND_DURATION,
  thumbDimensions,
  thumbMargins,
  TRACK_EXPAND_DURATION,
  TRACK_WIDTH,
} from './constants';

export const switchThumbVariants: Variants = {
  right: {
    x: TRACK_WIDTH - (thumbDimensions + thumbMargins * 2),
    transition: {
      ease: 'backInOut',
      delay:
        SVG_CIRCLE_HIDE_DURATION +
        TRACK_EXPAND_DURATION +
        SWITCH_EXPAND_DURATION,
      duration: 1,
      repeatType: 'reverse',
      repeat: Number.POSITIVE_INFINITY,
    },
  },
  left: {
    x: 0,
    transition: { delay: 0, duration: 0.5 },
  },
};

const SwitchThumb = styled(motion.div)({
  height: thumbDimensions,
  width: thumbDimensions,
  margin: '10px',
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  zIndex: 10,
  borderRadius: '50%',
});

export default SwitchThumb;
