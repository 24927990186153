import { SessionData } from '@/feat/auth/types';
import { AxiosRequestConfig } from 'axios';
import React from 'react';
import { axiCreate } from '../lib/axios-clean-trace';
import useSession, { useOnSessionUpdate } from './use-session';
import { env } from '@/env.mjs';

let isRedirecting = false;
export default function useV2Api({
  axiConfig,
}: {
  axiConfig?: AxiosRequestConfig<any> | undefined;
} = {}) {
  const configRef = React.useRef(axiConfig);
  const sessionDataRef = React.useRef<SessionData>();
  const { sessionFetchedPromise } = useSession();

  useOnSessionUpdate((sessionData) => {
    sessionDataRef.current = sessionData;
  });

  const client = React.useMemo(() => {
    const cfg = configRef.current;
    const axi = axiCreate({
      baseURL: env.NEXT_PUBLIC_DINEIN_BACKEND_API,
      timeout: 30 * 1000,
      ...cfg,
    });
    axi.interceptors.request.use(async (config) => {
      await sessionFetchedPromise;
      const hostV2AuthToken = sessionDataRef.current?.hostV2AuthToken;
      if (!hostV2AuthToken) {
        const controller = new AbortController();
        controller.abort('User is not logged in yet');
        return {
          ...config,
          signal: controller.signal,
        };
      }
      config.headers = {
        Authorization: `Bearer ${hostV2AuthToken}`,
        ...config.headers,
      };
      return config;
    });
    axi.interceptors.response.use(undefined, async (error) => {
      if (error?.response?.data?.statusCode === 401 && !isRedirecting) {
        isRedirecting = true;
        await fetch('/api/auth/logout').catch(console.error);
        const params = {
          continue: [
            window.location.pathname,
            window.location.search,
            window.location.hash,
          ].join(''),
        };
        // hard redirect to clear all states
        window.location.href =
          '/login?' + new URLSearchParams(params).toString();
        return (isRedirecting = false);
      }
      throw error;
    });
    return axi;
  }, [sessionFetchedPromise]);

  return client;
}
