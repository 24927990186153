import {
  ERROR,
  INFO,
  PRIMARY,
  SUCCESS,
  WARNING,
} from '@/minimals.cc/theme/palette';
import { alpha } from '@mui/material';
import { z } from 'zod';
import { ValueOf } from '../utils';

/**
 * PR: Payment Required
 */
export const PR_TICKET_TYPE_PAYMENT = {
  Deposit: 2,
  Prepaid: 3,
  CardGuarantee: 4,
} as const;
/**
 * PR: Payment Required
 */
export type PRTicketTypePayment = keyof typeof PR_TICKET_TYPE_PAYMENT;
/**
 * PR: Payment Required
 */
export type PRTicketTypePaymentInt = ValueOf<typeof PR_TICKET_TYPE_PAYMENT>;

export const TICKET_TYPE_PAYMENT = {
  ...PR_TICKET_TYPE_PAYMENT,
  'No Payment': 1,
} as const;
export type TicketTypePayment = keyof typeof TICKET_TYPE_PAYMENT;
export type TicketTypePaymentInt = ValueOf<typeof TICKET_TYPE_PAYMENT>;

export const TICKET_TYPE_PRICE = {
  PER_PAX: 1,
  PER_BOOKING: 2,
} as const;
export type TicketTypePriceInt = ValueOf<typeof TICKET_TYPE_PRICE>;

export const ZTicketWithoutRef = z.object({
  id: z.string(),
  brandId: z.string(),
  name: z.string(),
  x: z.string().nullish(),
  y: z.string().nullish(),
  description: z
    .string()
    .nullish()
    .transform((x) => x ?? ''),
  color: z
    .string()
    .nullish()
    .transform((x) => x ?? ''),
  image: z.string().nullish(),
  enableGoogleBooking: z.boolean().nullish(),
  showBookingPage: z.boolean().nullish(),
  typePayment: z.nativeEnum(TICKET_TYPE_PAYMENT).nullable().catch(null),
  price: z.number().nullish(),
  typePrice: z.nativeEnum(TICKET_TYPE_PRICE).nullish(),
  order: z.number().int().nullish(),
  usualPrice: z.number().nullish(),
  isShowUsualPrice: z.boolean().nullish(),
  specificTime: z.string().optional().nullish(),
  isBeforeSpecificTime: z
    .boolean()
    .nullish()
    .transform((x) => x ?? false),
  collectDeposit: z.number().int().nullish(),
  maxNumber: z.number().int().nullish(),
  typeNumber: z.number().int().optional().nullish(),
  refundWindow: z.string().nullish(),
  refundWindowMinAdvancedMinutes: z.number().int(),
  refundWindowMaxAdvancedMinutes: z.number().int().nullish(),
  refundWindowBeforeSpecificTime: z.string().nullish(),
  typeRefundWindow: z.number().int().nullish(),
  pdfMenu: z.string().nullish(),
  customPolicy: z.string().nullish(),
  isFeatureTicket: z
    .boolean()
    .nullish()
    .transform((x) => x ?? false),
  isActive: z
    .boolean()
    .nullish()
    .transform((x) => x ?? false),
  typeEditWindow: z.string().nullish(),
  editWindow: z.number().nullish(),
  isEnableSelfEdit: z.boolean().nullish(),
  showContactEditPage: z.boolean().nullish(),
  showContactCancelPage: z.boolean().nullish(),
});
export type TicketBase = z.infer<typeof ZTicketWithoutRef>;

export const ticketColor = (v2Color: string | null | undefined) => {
  const defaultColor = Object.values(TICKET_COLOR_MAPPING_V2_TO_V3)[0]!;
  if (!v2Color) return defaultColor;
  return (
    TICKET_COLOR_MAPPING_V2_TO_V3[v2Color] ||
    Object.values(TICKET_COLOR_MAPPING_V2_TO_V3).find(
      (it) => it.swatch === v2Color
    ) ||
    defaultColor
  );
};

export const V3_COLOR_SWATCHES = [
  PRIMARY.main,
  PRIMARY.light,
  INFO.main,
  INFO.light,
  SUCCESS.dark,
  SUCCESS.light,
  WARNING.main,
  WARNING.light,
  ERROR.dark,
  ERROR.light,
] as const;
export type V3ColorSwatch = typeof V3_COLOR_SWATCHES[number];

export type ColorMapping = {
  bg: string;
  stroke: string;
  font: string;
  swatch: V3ColorSwatch;
};
export const TICKET_COLOR_MAPPING_V2_TO_V3: Record<string, ColorMapping> = {
  '#D80000': {
    bg: ERROR.light,
    stroke: alpha(ERROR.main, 0.48),
    font: ERROR.darker,
    swatch: ERROR.dark,
  },
  '#ED7200': {
    bg: WARNING.light,
    stroke: alpha(WARNING.main, 0.48),
    font: WARNING.darker,
    swatch: WARNING.main,
  },
  '#FFC700': {
    bg: WARNING.lighter,
    stroke: alpha(WARNING.main, 0.48),
    font: WARNING.darker,
    swatch: WARNING.light,
  },
  '#86C500': {
    bg: SUCCESS.lighter,
    stroke: alpha(SUCCESS.main, 0.48),
    font: SUCCESS.darker,
    swatch: SUCCESS.light,
  },
  '#008C84': {
    bg: SUCCESS.light,
    stroke: alpha(SUCCESS.main, 0.48),
    font: SUCCESS.darker,
    swatch: SUCCESS.dark,
  },
  '#004EE7': {
    bg: INFO.light,
    stroke: alpha(INFO.main, 0.48),
    font: INFO.darker,
    swatch: INFO.main,
  },
  '#6900D1': {
    bg: PRIMARY.light,
    stroke: alpha(PRIMARY.main, 0.48),
    font: PRIMARY.darker,
    swatch: PRIMARY.main,
  },
  '#B600DA': {
    bg: PRIMARY.lighter,
    stroke: alpha(PRIMARY.main, 0.48),
    font: PRIMARY.darker,
    swatch: PRIMARY.light,
  },
  '#F60094': {
    bg: ERROR.lighter,
    stroke: alpha(ERROR.main, 0.48),
    font: ERROR.darker,
    swatch: ERROR.light,
  },
  '#8E8D8D': {
    bg: INFO.lighter,
    stroke: alpha(INFO.main, 0.48),
    font: INFO.darker,
    swatch: INFO.light,
  },
  '#3D0099': {
    bg: PRIMARY.light,
    stroke: alpha(PRIMARY.main, 0.48),
    font: PRIMARY.darker,
    swatch: PRIMARY.main,
  },
  '#F82626': {
    bg: ERROR.light,
    stroke: alpha(ERROR.main, 0.48),
    font: ERROR.darker,
    swatch: ERROR.dark,
  },
  '#FF4587': {
    bg: ERROR.lighter,
    stroke: alpha(ERROR.main, 0.48),
    font: ERROR.darker,
    swatch: ERROR.light,
  },
  '#FF9C41': {
    bg: WARNING.light,
    stroke: alpha(WARNING.main, 0.48),
    font: WARNING.darker,
    swatch: WARNING.main,
  },
};
