import { z } from 'zod';
import { ZTableBlock } from './table-block';

export const ZTableWithoutRef = z.object({
  id: z.string(),
  outletId: z.string().optional(),
  tableTypeId: z.string().nullable().optional(),
  name: z
    .string()
    .nullable()
    .transform((x) => x || ''),
  minPax: z.number(),
  maxPax: z.number(),
  shape: z.string().nullable().optional(),
  size: z.string().nullable().optional(),
  url: z.string().optional(),
  status: z.number().nullish(),
  enabled: z.boolean(),
  disableByDate: z.string().nullable().optional(),
  bookableOnline: z.boolean(),
  isGenerateByReserve: z.boolean().nullable().optional(),
  createdTimestamp: z.string().optional(),
  modifiedTimestamp: z.string().optional(),
  createdBy: z.string().nullable().optional(),
  modifiedBy: z.string().nullable().optional(),
  qrCode: z.string().optional(),
  disableDate: z.array(z.string()).optional(),
  rotate: z.number().nullable().optional(),
  tableBlocks: z.array(ZTableBlock).optional(),
  x: z.number().nullable().optional(),
  y: z.number().nullable().optional(),
});
