import { env } from '@/env.mjs';
import { redirect } from '../lib/router';
import useFnOnce from './use-fn-once';
import useSession from './use-session';

export const usePromptV3 = () => useFnOnce(() => redirect('/v3-prompt'));

export const useRedirectToV2AndLogin = () => {
  const { data: sessionData } = useSession();
  return useFnOnce(() => {
    const token = sessionData?.hostV2AuthToken;
    if (!token) {
      window.location.href = '/logout';
      return;
    }
    window.location.href =
      env.NEXT_PUBLIC_HOST_CLASSIC_URL +
      '/login-from-v3?' +
      new URLSearchParams({ token }).toString();
  });
};
