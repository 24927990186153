import { useEffect, useMemo } from 'react';
import useCallbackRef from './use-callback-ref';
import useLocalStorage from './use-local-storage';
import useSessionStorage from './use-session-storage';
import { parseISO } from 'date-fns';

export const useTopNavDate = () => {
  const [selectedDate, setSelectedDate, reset] = useLocalStorage(
    'selectedDate',
    new Date().toISOString()
  );
  const topNavDate = useMemo(() => parseISO(selectedDate), [selectedDate]);
  const setTopNavDate = useCallbackRef((date: Date) =>
    setSelectedDate(date.toISOString())
  );
  return [topNavDate, setTopNavDate, reset] as const;
};

export const useLastVisitedCustomer = () =>
  useSessionStorage<string>('customers::lastVisited', '');

export const loginMethods = ['EMAIL_PW', 'CODE_PIN'] as const;
export type LoginMethod = typeof loginMethods[number];
export const useLoginMethod = () =>
  useLocalStorage<LoginMethod>('loginMethod', 'CODE_PIN');

export const useCustomerSearchText = () =>
  useSessionStorage<string>('customers::search', '');

export const weeklyScheduleViewTypes = ['CALENDAR', 'LIST'] as const;
export type WeeklyScheduleViewType = typeof weeklyScheduleViewTypes[number];
export const useWeeklyScheduleViewType = () =>
  useLocalStorage<WeeklyScheduleViewType>('schedules::weeklyViewType', 'LIST');

export const usePreferredLocale = () =>
  useLocalStorage<string>('preferredLocale', '');

/**
 * use this hook to reset storage states that persist only within the login session
 *
 * note that this hook can trigger multiple rerenders (due to all the child hooks)
 */
export const useResetOutletStorage = () => {
  const [, , resetTopNavDate] = useTopNavDate();
  const [, , resetCustomerSearchText] = useCustomerSearchText();
  const [, , resetLastVisitedCustomer] = useLastVisitedCustomer();

  return useCallbackRef(() => {
    resetTopNavDate();
    resetCustomerSearchText();
    resetLastVisitedCustomer();
  });
};

/**
 * use this component to reset storage without triggering rerenders
 */
export const ResetOutletStorage = () => {
  const resetOutletStorage = useResetOutletStorage();

  useEffect(() => {
    resetOutletStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export const useIsMockImpersonating = () =>
  useLocalStorage<boolean>('isMockImpersonating', false);
