export interface TransitionVariant {
  duration: number;
  times: number[];
}

export const calculateTransitionVariants = (
  ...timeIntervals: number[]
): TransitionVariant => {
  const duration = timeIntervals.reduce((a, b) => a + b, 0);
  const times = [0];

  for (let i = 0; i < timeIntervals.length; i++) {
    times.push(times[i]! + timeIntervals[i]! / duration);
  }

  return { duration, times };
};
