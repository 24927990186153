// eslint-disable-next-line no-restricted-imports
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';

export default function axiosCleanStacktrace(axi: AxiosInstance) {
  const restoreAgent = axiosBetterStacktrace(axi);
  const interceptor = axi.interceptors.response.use(undefined, (error) => {
    error._jsonConfig = JSON.stringify(error.config);
    delete error.config;
    delete error.request;
    if (error.response) {
      delete error.response.request;
      delete error.response.config;
    }
    if (error.response?.data) {
      error.response._jsonData = JSON.stringify(error.response?.data);
    }
    return Promise.reject(error);
  });
  type EjectFn = () => void;
  const eject: EjectFn = () => {
    restoreAgent?.();
    axi.interceptors.request.eject(interceptor);
  };
  return eject;
}

export const axiCreate = (config?: AxiosRequestConfig) => {
  const instance = axios.create(config);
  axiosCleanStacktrace(instance);
  return instance;
};

export const axiClient = axiCreate({ timeout: 30_000 });
export const isAxiosError = axios.isAxiosError;
