// @ts-check
/* eslint-disable no-process-env */

// https://github.com/i18next/next-i18next/issues/1552#issuecomment-981156476
// https://github.com/i18next/next-i18next/issues/1623
// https://github.com/i18next/next-i18next/issues/1552#issuecomment-991786093
require('path').resolve('./public/locales');

const { nextI18nLocales } = require('./src/common/locales.cjs');

/**
 * @type {import('next-i18next').UserConfig}
 */
const userConfig = {
  i18n: {
    defaultLocale: 'DEFAULT_LOCALE',
    locales: nextI18nLocales,
  },
  serializeConfig: false,
  /**
   * t('some {{undefinedVar}} text') === 'some  text'
   */
  missingInterpolationHandler: () => undefined,
  pluralSeparator: '__',
  contextSeparator: '__',
  nsSeparator: false,
  fallbackLng: false,
  debug: process.env.DEBUG_I18NEXT === 'true',
  reloadOnPrerender: process.env.NODE_ENV === 'development',
};

module.exports = userConfig;
