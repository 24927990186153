import { ReactElement, ReactNode, useEffect, useLayoutEffect } from 'react';
import { NextPage } from 'next';
import { Ticket } from '@/common/types/ticket';
import { isPast, parse } from 'date-fns';
import { env } from '@/env.mjs';

// https://usehooks-ts.com/react-hook/use-isomorphic-layout-effect
export const useIsoLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export const isProduction = () => {
  if (env.NEXT_PUBLIC_VERCEL_ENV !== undefined)
    return env.NEXT_PUBLIC_VERCEL_ENV === 'production';
  return false;
};
export const isStaging = () => {
  if (env.NEXT_PUBLIC_VERCEL_ENV !== undefined)
    return env.NEXT_PUBLIC_VERCEL_ENV === 'preview';
  return false;
};
export const isDev = () => !isProduction() && !isStaging();

export const isLocal = () => !env.NEXT_PUBLIC_VERCEL_ENV;

export const devLog = (...args: Parameters<typeof console.log>) => {
  if (isProduction()) return;
  return console.log(...args);
};

export const getFallbackUserAvatar = (salutation?: string | null) => {
  const assetDir = '/images/avatar';

  if (!salutation) return '';

  switch (salutation) {
    case 'N/A':
      return '';
    case 'Mr':
      return `${assetDir}/salutation-mr-na.svg`;
    case 'Ms':
    case 'Mrs':
      return `${assetDir}/salutation-ms-mrs.svg`;
    case 'Dr':
      return `${assetDir}/salutation-dr.svg`;
    default:
      return '';
  }
};

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};
export type Extend<T, U> = U & Omit<T, keyof U>;
export type ValueOf<T> = T[keyof T];
export type NonEmptyArray<T> = [T, ...T[]];
export type NonEmptyArray2<T> = [T, T, ...T[]];
export type JsonPrimitive = string | number | boolean | null;
export type Jsonifiable =
  | JsonPrimitive
  | { [k: string]: Jsonifiable | undefined }
  | Jsonifiable[];
export type Falsy = false | 0 | '' | null | undefined;

type Singular = string;
type Plural = string;
export type DurationUnits = {
  years: [Singular, Plural];
  months: [Singular, Plural];
  weeks: [Singular, Plural];
  days: [Singular, Plural];
  hours: [Singular, Plural];
  minutes: [Singular, Plural];
  seconds: [Singular, Plural];
};

export const tryFn = <T>(fn: () => T): T | undefined => {
  try {
    return fn();
  } catch {
    return undefined;
  }
};
export const tryAsync = async <T>(
  fn: () => Promise<T> | T
): Promise<T | undefined> => {
  try {
    return await fn();
  } catch {
    return undefined;
  }
};

export const isOnlineTicket = (ticket: Ticket) => {
  if (!ticket.serviceTimings) {
    return false;
  }
  return ticket.serviceTimings.some((t) => {
    if (t.isRecurring) {
      return true;
    }
    if (
      t.specificDate &&
      !isPast(parse(t.specificDate, 'yyyy-MM-dd', new Date()))
    ) {
      return true;
    }
    return t.specificDateArray?.some(
      (d) => !isPast(parse(d, 'yyyy-MM-dd', new Date()))
    );
  });
};

/**
 * validates switch statement coverage for all union type cases
 * https://stephenweiss.dev/typescript-exhaustive-switch-statements
 */
export const assertUnreachable = (_: never): void => {
  if (!isProduction())
    throw new Error(
      'Should not have reached here -> check for missing `switch` cases of union type'
    );
};

// eslint-disable-next-line no-restricted-syntax
export const cloneDate = (d: Date) => new Date(d);
