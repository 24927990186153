import { z } from 'zod';

export const ZTableInAreaWithoutRef = z.object({
  id: z.string().nullish(),
  layoutAreaId: z.string().nullish(),
  tableId: z.string(),
  x: z.number(),
  y: z.number(),
  rotate: z.number().nullish(),
  priority: z.number().nullish(),
  createdTimestamp: z.string().nullish(),
  modifiedTimestamp: z.string().nullish(),
  deletedTimestamp: z.string().nullish(),
  layoutArea: z
    .object({
      id: z.string(),
      outletId: z.string(),
      layoutId: z.string().nullable(),
      name: z.string(),
      displayName: z.string().nullish(),
      order: z.number().nullable(),
      color: z.string().catch(''),
      bookableOnline: z.boolean().nullable(),
      combineAllTable: z.boolean().nullable(),
      createdTimestamp: z.string(),
      modifiedTimestamp: z.string().nullable(),
      deletedTimestamp: z.string().nullable(),
    })
    .nullish(),
});
